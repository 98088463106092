import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

const useBaseStore = create()(
  immer((set) => ({
    snackbar: {
      open: false,
      type: "",
      message: "",
      title: "",
      autoHideDuration: 6000,
    },
    setSnackbar: (snackbar) =>
      set((state) => {
        state.snackbar = {
          ...state.snackbar,
          ...snackbar,
        };
      }),
  }))
);

export default useBaseStore;
