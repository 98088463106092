// helper function for internal use.
// finds the largest number for the provided group of logic definitions.

import {
  LOGIC_TYPE_DISPLAY,
  GROUPS,
  OPTION_TYPE_IMPORT,
  OPTIONS,
  QUESTION,
  OPTION_TYPE_TEXT,
  BLOCK,
} from "./constants";

// logic definitions of any type have names: "xxxxxx-{number}"
const _findLargestLogicNum = (logicDefinitionsNames) => {
  //   let maxString = "";
  let maxNumber = -Infinity;

  if (!logicDefinitionsNames.length) return 1;

  for (const name of logicDefinitionsNames) {
    const number = parseInt(name.split("-").pop());

    if (number > maxNumber) {
      maxNumber = number;
      //   maxString = name;
    }
  }

  return maxNumber + 1;
};

// generates logic name of any type
const generateLogicName = ({ allLogicDefinitions, type, part }) => {
  // part could be "question", "options", "groups"
  // type could be "display", "import", "required", "variable", "unique"
  // allLogicDefinitions is the logicDefinitions array in the case of type: "question" else are all options/groups

  const isQuestion = part === QUESTION;

  const isBlock = part === BLOCK;

  const isImport = type === OPTION_TYPE_IMPORT;

  const isOptionDisplay =
    (part === OPTIONS || part === GROUPS) && type === LOGIC_TYPE_DISPLAY;

  if (isQuestion || isImport || isBlock) {
    const logicDefinitionsNames = allLogicDefinitions
      .filter((ld) => ld.type === type)
      .map((ld) => ld.name);

    return `${part}-${type}-${_findLargestLogicNum(logicDefinitionsNames)}`;
  }

  if (isOptionDisplay) {
    const logicDefinitionsNames = allLogicDefinitions
      .filter((ld) => ld.type === OPTION_TYPE_TEXT && ld.displayLogic)
      .map((ld) => ld.displayLogic.name);

    return `${part}-${type}-${_findLargestLogicNum(logicDefinitionsNames)}`;
  }

  throw new Error("something went wrong");
};

export { generateLogicName };
