import {
  Alert,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import useEditorStore from "../../../../../../../store/editor";
import { useEffect, useMemo, useState } from "react";
import { Close } from "@mui/icons-material";
import { List, AutoSizer, CellMeasurer } from "react-virtualized";
import { useRef } from "react";
import { createCellMeasurerCache } from "../../../../../../../helpers/createCellMeasurerCache";
import { useUpdateEffect } from "react-use";
import { shallow } from "zustand/shallow";
import { OPERATION_CHANGE_VALUE } from "../../../../../../../helpers/constants";
import useBaseStore from "../../../../../../../store/base";

const AddQuestions = ({ blockIndex, onDialogClose, setScrollToIndex }) => {
  const cacheRef = useRef(null);
  const test = useRef(null);
  if (!cacheRef.current) {
    cacheRef.current = createCellMeasurerCache(61); // create a new cache each time this component is first rendered
  }

  const [
    surveyDoc,
    changeEditorSurvey,
    blockName,
    allQuestions,
    blockQuestions,
  ] = useEditorStore(
    (state) => [
      state.surveyDoc,
      state.changeEditorSurvey,
      state.editorSurvey.blocks[blockIndex].name,
      state.editorSurvey.questions,
      state.editorSurvey.blocks[blockIndex].questions,
    ],
    shallow
  );

  const setSnackbar = useBaseStore((state) => state.setSnackbar);

  const [questionsDictionary, setQuestionsDictionary] = useState(
    allQuestions.reduce((acc, item) => {
      acc[item.id] = {
        name: item.name,
        checked: false,
      };
      return acc;
    }, {})
  );
  // create a dictionary out of all questions
  // used to easily mark a checkbox as checked without a loop in the template

  const [search, setSearch] = useState("");

  useUpdateEffect(() => {
    setQuestionsDictionary((prevDict) => {
      return allQuestions.reduce((acc, item) => {
        acc[item.id] = {
          name: item.name,
          checked: prevDict[item.id]?.checked || false,
        };
        return acc;
      }, {});
    });
  }, [allQuestions]); // update dictionary when allQuestions change, for example if a question is deleted or edited

  const allQuestionsWithAddedFlag = useMemo(() => {
    return allQuestions.map((q) => ({
      ...q,
      added: blockQuestions.includes(q.id),
    }));
  }, [allQuestions, blockQuestions]);
  // all questions with an "added" flag to disable adding existing questions to block

  const filteredQuestions = useMemo(() => {
    return allQuestionsWithAddedFlag.filter((q) =>
      q.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [allQuestionsWithAddedFlag, search]);

  useEffect(() => {
    cacheRef.current.clearAll();
    test.current.recomputeRowHeights(); // recalculate row heights when searching questions
  }, [filteredQuestions]);

  const checkedQuestions = useMemo(() => {
    return Object.keys(questionsDictionary)
      .filter((qKey) => questionsDictionary[qKey].checked)
      .map((qKey) => ({ id: qKey, ...questionsDictionary[qKey] }));
  }, [questionsDictionary]); // create an array out of the dictionary with only the checked questions

  const buttonLabel = useMemo(() => {
    if (!checkedQuestions.length) return "Add Questions";

    return `Add ${checkedQuestions.length} Question${
      checkedQuestions.length === 1 ? "" : "s"
    }`;
  }, [checkedQuestions]);

  const handleChange = (checked, id) => {
    const question = questionsDictionary[id];

    setQuestionsDictionary({
      ...questionsDictionary,
      [id]: {
        ...question,
        checked,
      },
    });
  };

  const addQuestion = () => {
    const checkedQuestionsMapped = checkedQuestions.map((q) => q.id);

    const operations = checkedQuestionsMapped.map((q, index) => {
      return {
        p: ["blocks", blockIndex, "questions", blockQuestions.length + index],
        li: q,
      };
    }); // multiple list insert operations

    const submitOp = () => {
      surveyDoc.submitOp(operations);
    };

    changeEditorSurvey({
      path: ["blocks", blockIndex, "questions"],
      newValue: [...blockQuestions, ...checkedQuestionsMapped],
      operation: OPERATION_CHANGE_VALUE,
      submitOp,
    });

    setScrollToIndex(blockQuestions.length);

    setSnackbar({
      open: true,
      type: "success",
      message: `${checkedQuestionsMapped.length} question${
        checkedQuestionsMapped.length === 1 ? "" : "s"
      } added to block "${blockName}"`,
    });

    onDialogClose();
  };

  return (
    <>
      <Typography
        variant="subtitle1"
        sx={{ fontWeight: 500, textAlign: "center", mb: 1 }}
      >
        Add questions to block "{blockName}"
      </Typography>

      <Stack
        direction="row"
        gap={1}
        sx={{
          alignSelf: "center",
          alignItems: "center",
          justifyContent: "center",
          mb: 1,
        }}
      >
        <TextField
          size="small"
          label={`Search questions by ID`}
          variant="outlined"
          sx={{ width: "400px" }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        {search && (
          <IconButton
            size="small"
            color="primary"
            onClick={() => setSearch("")}
          >
            <Close fontSize="20px" />
          </IconButton>
        )}
      </Stack>

      <Stack sx={{ flexGrow: 1 }} gap={1}>
        {!search && !allQuestions.length && (
          <Alert severity="info">No questions are added</Alert>
        )}

        {search && !filteredQuestions.length && (
          <Alert severity="warning">No questions found</Alert>
        )}

        <AutoSizer>
          {({ width, height }) => (
            <List
              width={width}
              height={height}
              rowHeight={cacheRef.current.rowHeight}
              deferredMeasurementCache={cacheRef.current}
              rowCount={filteredQuestions.length}
              overscanRowCount={3}
              ref={test}
              rowRenderer={({ index, key, style, parent }) => (
                <CellMeasurer
                  key={key}
                  cache={cacheRef.current}
                  parent={parent}
                  columnIndex={0}
                  rowIndex={index}
                >
                  {({ registerChild }) => (
                    <Stack
                      gap={1}
                      style={style}
                      ref={registerChild}
                      sx={{ pt: 1 }}
                    >
                      <Stack
                        direction="row"
                        sx={{ alignItems: "center", px: 1 }}
                        gap={1}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={filteredQuestions[index].added}
                              checked={
                                !filteredQuestions[index].added &&
                                questionsDictionary[filteredQuestions[index].id]
                                  .checked
                              }
                              onChange={(e) =>
                                handleChange(
                                  e.target.checked,
                                  filteredQuestions[index].id
                                )
                              }
                              size="small"
                            />
                          }
                          sx={{ margin: 0 }}
                          label={
                            <Stack>
                              <Typography sx={{ fontWeight: 500 }}>
                                {filteredQuestions[index].name}
                              </Typography>
                              <Typography variant="caption">
                                {filteredQuestions[index].title}
                              </Typography>
                            </Stack>
                          }
                        />

                        {filteredQuestions[index].added && (
                          <Chip
                            label="Added"
                            variant="outlined"
                            color="primary"
                            size="small"
                          />
                        )}
                      </Stack>

                      <Divider />
                    </Stack>
                  )}
                </CellMeasurer>
              )}
            />
          )}
        </AutoSizer>
      </Stack>

      {Boolean(allQuestions.length) && (
        <Stack>
          {Boolean(checkedQuestions.length) && (
            <Stack
              direction="row"
              sx={{ flexWrap: "wrap", p: 1, maxHeight: 72, overflowY: "auto" }}
              gap={1}
            >
              {checkedQuestions.map((q, index) => (
                <Chip
                  key={index}
                  label={q.name}
                  size="small"
                  onDelete={() => handleChange(false, q.id)}
                />
              ))}
            </Stack>
          )}

          <Button
            variant="contained"
            sx={{ borderRadius: 0 }}
            disabled={!checkedQuestions.length}
            onClick={addQuestion}
          >
            {buttonLabel}
          </Button>
        </Stack>
      )}
    </>
  );
};

export default AddQuestions;
