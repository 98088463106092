import { Divider, IconButton, Menu, Stack, Typography } from "@mui/material";
import { useMemo, useState, cloneElement } from "react";
import { useNavigate } from "react-router-dom";
import tabColumns from "./tabColumns";
import { Delete, DragHandle } from "@mui/icons-material";
import { getValueBasedOnPath } from "../../../../../helpers/getValueBasedOnPath";
import DeleteTabRow from "./DeleteTabRow";

const getStyle = (provided, style) => {
  if (!style) {
    return provided.draggableProps.style;
  }

  return {
    ...provided.draggableProps.style,
    ...style,
  };
};

const TabTableRow = ({
  row,
  rowIndex,
  provided,
  style,
  hideDragAndDrop = false,
  tab,
  DeleteRowComponent,
  navigateParams,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const columns = useMemo(() => {
    return tabColumns[tab].map((column) => ({
      ...column,
      field: getValueBasedOnPath(row, column.field),
    }));
  }, [tab, row]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Stack
        onClick={() =>
          navigate(navigateParams ? navigateParams(row.id) : row.id)
        }
        {...provided.draggableProps}
        ref={provided.innerRef}
        style={getStyle(provided, style)}
      >
        <Stack
          direction="row"
          sx={{
            px: 2,
            height: 36,
            alignItems: "center",
            cursor: "pointer",
            borderRadius: "4px",
            "&:hover": { backgroundColor: "grey.100" },
          }}
          gap={1}
        >
          {!hideDragAndDrop && (
            <Stack sx={{ flex: 1 }}>
              <Stack {...provided.dragHandleProps}>
                <DragHandle
                  sx={{
                    color: "grey.900",
                  }}
                />
              </Stack>
            </Stack>
          )}

          {columns.map((column, index) => (
            <Stack
              sx={{
                flex: 2,
                justifyContent: "center",
                alignItems: "start",
              }}
              key={index}
            >
              <Typography variant="body2">{column.field}</Typography>
            </Stack>
          ))}

          <Stack sx={{ flex: 1 }}>
            <IconButton
              size="small"
              color="error"
              sx={{ ml: "auto" }}
              onClick={(e) => {
                e.stopPropagation();
                handleMenuOpen(e);
              }}
            >
              <Delete fontSize="20px" />
            </IconButton>
          </Stack>
        </Stack>

        <Divider />
      </Stack>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transitionDuration={0}
      >
        <Stack>
          {Boolean(anchorEl) &&
            (DeleteRowComponent ? (
              cloneElement(DeleteRowComponent, {
                handleMenuClose,
                row,
                rowIndex,
              })
            ) : (
              <DeleteTabRow
                handleMenuClose={handleMenuClose}
                row={row}
                index={rowIndex}
              />
            ))}
        </Stack>
      </Menu>
    </>
  );
};

export default TabTableRow;
