import {
  FLAG_ALL,
  FLAG_EXCLUSIVE,
  FLAG_FIXED,
  FLAG_UNIQUE,
  FLAG_WRITE_IN,
} from "./constants";

export const showFlags = (type, selector) => {
  const flags = {
    CHECKBOX_LIST: {
      options: [FLAG_EXCLUSIVE, FLAG_FIXED, FLAG_WRITE_IN],
    },
    RADIO_LIST: {
      options: [FLAG_FIXED, FLAG_WRITE_IN],
    },
    RATING_LIST: {
      options: [FLAG_FIXED],
    },
    CHECKBOX_GRID: {
      options: [FLAG_EXCLUSIVE, FLAG_FIXED],
      groups: [FLAG_EXCLUSIVE, FLAG_FIXED, FLAG_WRITE_IN],
    },
    RADIO_GRID: {
      options: [FLAG_FIXED, FLAG_ALL, FLAG_UNIQUE],
      groups: [FLAG_FIXED, FLAG_WRITE_IN],
    },
    RATING_GRID: {
      options: [FLAG_FIXED],
      groups: [FLAG_FIXED],
    },
  };

  return flags?.[type]?.[selector] || [];
}; // returns the correct flags per type and selector, defaults to empty array
