import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import useEditorStore from "../../store/editor";
import { getValueBasedOnPath } from "../../helpers/getValueBasedOnPath";
import { shallow } from "zustand/shallow";
import { OPERATION_CHANGE_VALUE } from "../../helpers/constants";

const RealtimeSelect = ({ label, path, options }) => {
  const value = useEditorStore((state) =>
    getValueBasedOnPath(state.editorSurvey, path)
  );
  const [surveyDoc, changeEditorSurvey] = useEditorStore(
    (state) => [state.surveyDoc, state.changeEditorSurvey],
    shallow
  );

  const handleChange = (e) => {
    const submitOp = () => {
      surveyDoc.submitOp({
        p: path,
        od: value,
        oi: e.target.value,
      });
    };

    changeEditorSurvey({
      path,
      newValue: e.target.value,
      operation: OPERATION_CHANGE_VALUE,
      submitOp,
    });
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel>{label}</InputLabel>

      <Select value={value} label="Age" onChange={handleChange} size="small">
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default RealtimeSelect;
