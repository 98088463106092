import { useEffect, useMemo, useState } from "react";
import useEditorStore from "../../../../../store/editor";
import { shallow } from "zustand/shallow";
import useBaseStore from "../../../../../store/base";
import { useParams } from "react-router-dom";
import { OPERATION_ARRAY_REMOVE } from "../../../../../helpers/constants";
import { Button, Stack, TextField, Typography } from "@mui/material";
import pluralize from "pluralize";
import capitalizeFirstLetter from "../../../../../helpers/capitalizeFirstLetter";

const DeleteTabRow = ({ row, index, handleMenuClose }) => {
  const { tab } = useParams();
  const [confirmText, setConfirmText] = useState("");
  const [initialRow] = useState(row);
  const [surveyDoc, changeEditorSurvey] = useEditorStore(
    (state) => [state.surveyDoc, state.changeEditorSurvey],
    shallow
  );
  const setSnackbar = useBaseStore((state) => state.setSnackbar);

  useEffect(() => {
    if (JSON.stringify(initialRow) !== JSON.stringify(row)) {
      handleMenuClose();
    }
  }, [row, initialRow, handleMenuClose]);

  const singularTab = useMemo(() => {
    return pluralize.singular(tab);
  }, [tab]);

  const onDelete = () => {
    const submitOp = () => {
      surveyDoc.submitOp({
        p: [tab, index],
        ld: { ...row, originalIndex: undefined },
      });
    };

    changeEditorSurvey({
      path: [tab],
      newValue: { ...row, originalIndex: undefined }, // remove original index in order to compare objects
      operation: OPERATION_ARRAY_REMOVE,
      submitOp,
    });

    setSnackbar({
      open: true,
      type: "warning",
      message: `${capitalizeFirstLetter(singularTab)} Deleted`,
    });

    handleMenuClose();
  };

  return (
    <Stack gap={1} sx={{ p: 1 }}>
      <Typography variant="body2" sx={{ textAlign: "center" }}>
        Are you sure you want to delete {singularTab} "
        <strong>{row?.name}</strong>" ?
      </Typography>

      <TextField
        size="small"
        variant="outlined"
        label="Type delete to confirm"
        value={confirmText}
        onKeyDown={(e) => e.stopPropagation()}
        onChange={(e) => setConfirmText(e.target.value)}
      />

      <Stack direction="row" gap={1} sx={{ justifyContent: "center" }}>
        <Button size="small" onClick={handleMenuClose}>
          Cancel
        </Button>

        <Button
          size="small"
          color="error"
          variant="contained"
          disabled={confirmText !== "delete"}
          onClick={onDelete}
        >
          Delete
        </Button>
      </Stack>
    </Stack>
  );
};

export default DeleteTabRow;
