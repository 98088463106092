const tabColumns = {
  questions: [
    { label: "Name", field: ["name"] },
    { label: "Type", field: ["type"] },
  ],
  blocks: [
    { label: "Name", field: ["name"] },
    { label: "Number of Questions", field: ["questions", "length"] },
  ],
  collections: [],
  versions: [],
  branches: [],
  root: [],
  pages: [],
  triggers: [],
};

export default tabColumns;
