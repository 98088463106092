/* eslint  no-useless-escape: 0 */

import { useMonaco } from "@monaco-editor/react";
import { useEffect, useState } from "react";

// survey tool language support on monaco
const useLanguageSupport = () => {
  const [init, setInit] = useState(false);
  const monaco = useMonaco();

  useEffect(() => {
    if (monaco) {
      //register custom lang
      monaco.languages.register({ id: "survey-lang" });

      //keywords
      const keywords = [
        "lambda",
        "every",
        "some",
        "seen",
        "answered",
        "question",
        "value",
        "groups",
        "options",
        "defined",
        "variable",
        "score",
        "set",
        "true",
        "false",
      ];

      //operators
      const operators = ["*", "=", "not", "and", "or", "@"];

      //add keywords
      monaco.languages.setMonarchTokensProvider("survey-lang", {
        keywords,
        operators,
        tokenizer: {
          root: [
            [
              /[a-z_$][\w$]*/,
              {
                cases: {
                  "@operators": "operator",
                  "@keywords": "keyword",
                  "@default": "identifier",
                },
              },
            ],
            // whitespace
            { include: "@whitespace" },
            // delimiters and operators
            [/\(/, "openingRoundBracket"],
            [/\)/, "closingRoundBracket"],
            [/\[/, "openingSquareBracket"],
            [/\]/, "closingSquareBracket"],
            // numbers
            [/\d*\.\d+([eE][\-+]?\d+)?/, "number.float"],
            [/\d+/, "number"],
            // delimiter: after number because of .\d floats
            [/[;,.]/, "delimiter"],
            // strings
            [/'([^'\\]|\\.)*$/, "string.invalid"], // non-teminated string
            [/"([^"\\]|\\.)*$/, "string.invalid"], // non-teminated string
            [/"/, "string", "@string_double"],
            [/'/, "string", "@string_single"],
          ],
          whitespace: [
            [/[ \t\r\n]+/, "white"],
            [/#.*$/, "comment"],
          ],
          comment: [
            [/[^#*]+/, "comment"],
            [/#\*/, "comment", "@push"], // nested comment
            ["\\*/", "comment", "@pop"],
            [/[#*]/, "comment"],
          ],
          string_single: [
            [/[^\\']+/, "string"],
            [/\\./, "string.escape.invalid"],
            [/'/, "string", "@pop"],
          ],
          string_double: [
            [/[^\\"]+/, "string"],
            [/\\./, "string.escape.invalid"],
            [/"/, "string", "@pop"],
          ],
        },
      });

      monaco.languages.setLanguageConfiguration("survey-lang", {
        //auto close brackets
        autoClosingPairs: [
          { open: "[", close: "]" },
          { open: "(", close: ")" },
          { open: "'", close: "'", notIn: ["string", "comment"] },
          { open: '"', close: '"', notIn: ["string", "comment"] },
        ],
        // for bracket highlighting
        brackets: [
          ["(", ")"],
          ["[", "]"],
          ["'", "'"],
          ['"', '"'],
        ],
        colorizedBracketPairs: [],
      });

      //define custom theme
      monaco.editor.defineTheme("survey-lang-theme", {
        base: "vs-dark",
        inherit: true,
        rules: [
          { token: "identifier", foreground: "#1a9bdb" },

          { token: "keyword", foreground: "#FF6600", fontStyle: "bold" },
          { token: "operator", foreground: "#ffffff", fontStyle: "bold" },

          {
            token: "openingRoundBracket",
            foreground: "#FF6600",
            fontStyle: "bold",
          },
          {
            token: "closingRoundBracket",
            foreground: "#FF6600",
            fontStyle: "bold",
          },
          {
            token: "openingSquareBracket",
            foreground: "#FF6600",
            fontStyle: "bold",
          },
          {
            token: "closingSquareBracket",
            foreground: "#FF6600",
            fontStyle: "bold",
          },

          { token: "number", foreground: "#db9927" },
          { token: "number.float", foreground: "#db9927" },

          { token: "delimiter", foreground: "#ffffff", fontStyle: "bold" },

          { token: "comment", foreground: "#999999" },

          { token: "string.invalid", foreground: "#fc0303" },
          { token: "string", foreground: "#32a852" },
        ],
        colors: {},
      });

      // autocomplete
      monaco.languages.registerCompletionItemProvider("survey-lang", {
        provideCompletionItems: (model, position) => {
          const last_chars = model.getValueInRange({
            startLineNumber: position.lineNumber,
            startColumn: 0,
            endLineNumber: position.lineNumber,
            endColumn: position.column,
          });

          // check if is second argument
          const numOfopeningParenthesis = (last_chars.match(/\(/g) || [])
            .length;
          const numOfclosginParenthesis = (last_chars.match(/\)/g) || [])
            .length;

          const isSecondArgument =
            numOfopeningParenthesis > numOfclosginParenthesis &&
            last_chars.replace(/ /g, "").slice(-2).indexOf(",") !== -1;

          const suggestions = [
            ...keywords.map((k) => {
              // Function type if keyword is not true or false
              const kind =
                k === "true" || k === "false"
                  ? monaco.languages.CompletionItemKind.Keyword
                  : monaco.languages.CompletionItemKind.Function;

              // show parenthesis if kind is Function and NOT a second argument of some or every
              const showParenthesis =
                kind === monaco.languages.CompletionItemKind.Function &&
                !isSecondArgument;

              return {
                label: k,
                kind,
                insertText: showParenthesis ? `${k}()` : k,
              };
            }),
          ];

          return { suggestions };
        },
      });

      setInit(true);
    }
  }, [monaco]);

  return init;
};

export default useLanguageSupport;
