// gets a nested object's property based on provided path. It is used on zustand selectors

const getValueBasedOnPath = (state, path) => {
  let currentObj = state;

  for (let i = 0; i < path.length - 1; i++) {
    const key = path[i];

    // Traverse into the object based on the current path element
    if (currentObj.hasOwnProperty(key)) {
      currentObj = currentObj[key];
    } else {
      // If the path is invalid, return or handle the error accordingly
      return "";
    }
  }

  const lastKey = path[path.length - 1];

  return currentObj ? currentObj[lastKey] : "";
};

export { getValueBasedOnPath };
