import { useEffect, useMemo, useState } from "react";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import useSurveysStore from "../../../../store/surveys";
import useBaseStore from "../../../../store/base";
import { useMount } from "react-use";
import getJwt from "../../../../helpers/getJwt";
import ReconnectingWebSocket from "reconnecting-websocket";
import useEditorStore from "../../../../store/editor";
import { Connection } from "sharedb/lib/client";
import { cloneDeep } from "lodash";
import {
  CircularProgress,
  Dialog,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import { shallow } from "zustand/shallow";
import ImportFromSpreadsheet from "./ImportFromSpreadsheet";

const Editor = () => {
  const [loader, setLoader] = useState(true);
  const { tab, id } = useParams();
  const currentSurveyName = useSurveysStore(
    (state) => state.currentSurvey.name
  );
  const setSnackbar = useBaseStore((state) => state.setSnackbar);
  const [
    setSocket,
    setSurveyDoc,
    setEditorSurvey,
    editorSurveyInitialized,
    surveyDoc,
  ] = useEditorStore(
    (state) => [
      state.setSocket,
      state.setSurveyDoc,
      state.setEditorSurvey,
      state.editorSurveyInitialized,
      state.surveyDoc,
    ],
    shallow
  );
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialog, setDialog] = useState(false);

  useMount(async () => {
    if (editorSurveyInitialized) return setLoader(false); // if editorSurveyInitialized is true then return early

    const jwt = await getJwt();
    const url = new URL(process.env.REACT_APP_EDITOR_SHAREDB_URL);
    url.searchParams.append("token", jwt); // construct url and append the jwt in the params.

    const socket = new ReconnectingWebSocket(url.href, [], {
      maxRetries: 3,
    }); // connect to the socket using the above url

    setSocket(socket); // save the socket instance to the store

    const connection = new Connection(socket); // create a connection

    const doc = connection.get("surveys", id); // get the survey from shareDB using the currentSurvey.id

    setSurveyDoc(doc); // save the survey document to the store

    doc.subscribe((e) => {
      // subscribe to the document
      if (e) {
        console.error(e);

        setSnackbar({
          open: true,
          type: "error",
          message: e?.message ? e.message : "Could not access the editor.",
        });

        return navigate("/");
      } // if there is an error open snackbar and return

      // If doc.type is undefined, the document has not been created, so let's create it
      if (!doc.type) {
        doc.create(
          {
            questions: [], // TODO: create with proper initial object
            blocks: [],
          },
          (e) => {
            if (e) {
              setSnackbar({
                open: true,
                type: "error",
                message: e?.message
                  ? e.message
                  : "Could not access the editor.",
              });

              return navigate("/");
            }
          }
        );
      }

      setEditorSurvey({
        editorSurvey: cloneDeep(doc.data),
      });

      setLoader(false);
    });
  });

  useEffect(() => {
    if (!editorSurveyInitialized) return;

    const onOp = (_e, source) => {
      if (source) return;

      setEditorSurvey({ editorSurvey: cloneDeep(surveyDoc.data) });
    };

    surveyDoc.on("op", onOp);

    return () => surveyDoc.removeListener("op", onOp);
  }, [editorSurveyInitialized, surveyDoc, setEditorSurvey]);

  const open = useMemo(() => {
    return Boolean(anchorEl);
  }, [anchorEl]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openDialog = () => {
    setDialog(true);
    handleClose();
  };

  return (
    <Stack
      sx={{
        flexGrow: 1,
      }}
    >
      {loader ? (
        <CircularProgress
          sx={{ alignSelf: "center", my: "auto" }}
          size="100px"
          thickness={2}
        />
      ) : (
        <>
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body1" sx={{ fontWeight: 600 }}>
              Survey Name:
            </Typography>

            <Typography variant="body1" component="span">
              {currentSurveyName}
            </Typography>

            <IconButton color="primary" onClick={handleOpen}>
              <CloudUpload />
            </IconButton>

            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              <MenuItem onClick={handleClose}>Deploy Survey</MenuItem>
              <MenuItem onClick={openDialog}>Import from spreadsheet</MenuItem>
              <MenuItem onClick={handleClose}>Export to spreadsheet</MenuItem>
            </Menu>
          </Stack>

          <Tabs
            sx={{ mb: 2, alignSelf: "center" }}
            value={tab ? tab : "questions"}
          >
            <Tab label="Root" value="root" component={Link} to="root" />

            <Tab
              label="Branches"
              value="branches"
              component={Link}
              to="branches"
            />

            <Tab
              label="Versions"
              value="versions"
              component={Link}
              to="versions"
            />

            <Tab
              label="Collections"
              value="collections"
              component={Link}
              to="collections"
            />

            <Tab label="Blocks" value="blocks" component={Link} to="blocks" />

            <Tab
              label="Questions"
              value="questions"
              component={Link}
              to="questions"
            />

            <Tab label="Pages" value="pages" component={Link} to="pages" />

            <Tab
              label="Triggers"
              value="triggers"
              component={Link}
              to="triggers"
            />
          </Tabs>

          <Outlet />
        </>
      )}

      <Dialog onClose={() => setDialog(false)} open={dialog}>
        <Stack sx={{ width: "600px", padding: 2 }}>
          <ImportFromSpreadsheet closeDialog={() => setDialog(false)} />
        </Stack>
      </Dialog>
    </Stack>
  );
};

export default Editor;
