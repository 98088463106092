import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import useEditorStore from "../../../../../store/editor";
import { shallow } from "zustand/shallow";
import useBaseStore from "../../../../../store/base";
import { Button, Stack, TextField } from "@mui/material";
import pluralize from "pluralize";
import capitalizeFirstLetter from "../../../../../helpers/capitalizeFirstLetter";
import { v4 as uuidv4 } from "uuid";
import { OPERATION_ARRAY_INSERT } from "../../../../../helpers/constants";

const NewTabRow = ({ handleMenuClose, setScrollToIndex }) => {
  const { tab } = useParams();
  const [name, setName] = useState("");
  const [surveyDoc, changeEditorSurvey, tabRowsLength] = useEditorStore(
    (state) => [
      state.surveyDoc,
      state.changeEditorSurvey,
      state.editorSurvey[tab].length,
    ],
    shallow
  );
  const setSnackbar = useBaseStore((state) => state.setSnackbar);

  const singularTab = useMemo(() => {
    return pluralize.singular(tab);
  }, [tab]);

  const onSubmit = (e) => {
    e.preventDefault();

    let newRow = null;

    switch (tab) {
      case "questions":
        newRow = {
          id: uuidv4(),
          content: "",
          description: "",
          groups: [],
          groupsRandomized: false,
          logicDefinitions: [],
          maxGroupsAllowed: "",
          maxOptionsAllowed: "",
          name,
          options: [],
          optionsRandomized: false,
          title: "",
          type: "CHECKBOX_LIST",
        };
        break;
      case "blocks":
        newRow = {
          id: uuidv4(),
          name,
          questions: [],
          logicDefinitions: [],
        };
        break;
      default:
        console.log(`${tab} not implemented`);
    }

    if (newRow) {
      const submitOp = () => {
        surveyDoc.submitOp([
          {
            p: [tab, tabRowsLength],
            li: newRow,
          },
        ]);
      };

      changeEditorSurvey({
        path: [tab],
        newValue: newRow,
        operation: OPERATION_ARRAY_INSERT,
        submitOp,
      });

      setScrollToIndex(tabRowsLength);

      setSnackbar({
        open: true,
        type: "success",
        message: `${capitalizeFirstLetter(singularTab)} Created!`,
      });
    }

    handleMenuClose();
  };

  return (
    <Stack
      sx={{ padding: 1, alignItems: "center" }}
      direction="row"
      gap={2}
      component="form"
      onSubmit={onSubmit}
    >
      <TextField
        label={`${capitalizeFirstLetter(singularTab)} name`}
        variant="outlined"
        size="small"
        value={name}
        onKeyDown={(e) => e.stopPropagation()}
        onChange={(e) => {
          setName(e.target.value);
        }}
      />

      <Button variant="contained" size="small" type="submit" disabled={!name}>
        Add
      </Button>
    </Stack>
  );
};

export default NewTabRow;
