import useEditorStore from "../../../../../../../store/editor";
import { Button, Stack, Typography } from "@mui/material";
import { shallow } from "zustand/shallow";
import { OPERATION_ARRAY_REMOVE } from "../../../../../../../helpers/constants";

const DeleteQuestionlogic = ({ handleClose, index, questionIndex }) => {
  const [surveyDoc, changeEditorSurvey, questionLogic] = useEditorStore(
    (state) => [
      state.surveyDoc,
      state.changeEditorSurvey,
      state.editorSurvey.questions[questionIndex].logicDefinitions[index],
    ],
    shallow
  );

  const onDelete = () => {
    const submitOp = () => {
      surveyDoc.submitOp({
        p: ["questions", questionIndex, "logicDefinitions", index],
        ld: questionLogic,
      });
    };

    changeEditorSurvey({
      path: ["questions", questionIndex, "logicDefinitions"],
      newValue: questionLogic,
      operation: OPERATION_ARRAY_REMOVE,
      submitOp,
    });

    handleClose();
  };

  return (
    <Stack gap={1} sx={{ p: 1 }}>
      <Typography variant="body2" sx={{ textAlign: "center" }}>
        Are you sure you want to delete question logic "
        <strong>{questionLogic.name}</strong>" ?
      </Typography>

      <Stack direction="row" gap={1} sx={{ justifyContent: "center" }}>
        <Button size="small" onClick={handleClose}>
          Cancel
        </Button>

        <Button
          size="small"
          color="error"
          variant="contained"
          onClick={() => onDelete()}
        >
          Delete
        </Button>
      </Stack>
    </Stack>
  );
};

export default DeleteQuestionlogic;
