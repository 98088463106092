import { Editor } from "@monaco-editor/react";
import { useEffect, useMemo, useRef, useState } from "react";
import useEditorStore from "../../store/editor";
import jsondiff from "json0-ot-diff";
import diffMatchPatch from "diff-match-patch";
import { shallow } from "zustand/shallow";
import { getValueBasedOnPath } from "../../helpers/getValueBasedOnPath";
import { OPERATION_CHANGE_VALUE } from "../../helpers/constants";

// index and optionId props are passed only from the options component. else will be just undefined
const RealtimeMonaco = ({ path, optionId, index }) => {
  const [monacoMounted, setMonacoMounted] = useState(false);
  const value = useEditorStore((state) =>
    getValueBasedOnPath(state.editorSurvey, path)
  );
  const [surveyDoc, changeEditorSurvey, focusedInput, setFocusedInput] =
    useEditorStore(
      (state) => [
        state.surveyDoc,
        state.changeEditorSurvey,
        state.focusedInput,
        state.setFocusedInput,
      ],
      shallow
    );
  const inputKey = useMemo(() => path[path.length - 1], [path]);
  const editorRef = useRef(null);

  useEffect(() => {
    if (monacoMounted) {
      const focusListener = editorRef.current.onDidFocusEditorWidget(() => {
        setFocusedInput({
          optionId,
          inputKey,
          index,
        });
      });

      const blurListener = editorRef.current.onDidBlurEditorWidget(() => {
        setFocusedInput(null);
      });

      return () => {
        focusListener.dispose();
        blurListener.dispose();
      };
    }
  }, [monacoMounted, setFocusedInput, index, inputKey, optionId]);

  useEffect(() => {
    if (
      monacoMounted &&
      focusedInput?.optionId === optionId &&
      focusedInput?.inputKey === inputKey &&
      focusedInput?.index !== index
    ) {
      // if focusedInput optionId and inputKey are the same as this component, but different index
      // which means the remote user has reordered the list
      // then focus this component
      editorRef.current.focus();
    }
  }, [index, optionId, inputKey, focusedInput, monacoMounted]);

  const handleInputChange = (e) => {
    let newValue = e;

    let diff = jsondiff(value, newValue, diffMatchPatch);

    diff = diff.map((d) => {
      return {
        ...d,
        p: [...path, ...d.p],
      };
    });

    const submitOp = () => {
      surveyDoc.submitOp(diff);
    };

    changeEditorSurvey({
      path,
      newValue,
      operation: OPERATION_CHANGE_VALUE,
      submitOp,
    });
  };

  const handleOnMount = (editor) => {
    // save the editor on mount for future use
    editorRef.current = editor;

    editor.updateOptions({
      fontSize: 16,
    });

    const model = editorRef.current.getModel();

    model.setEOL(0);

    setMonacoMounted(true);
  };

  return (
    <>
      <Editor
        width="100%"
        height="200px"
        theme="survey-lang-theme"
        language="survey-lang"
        onMount={handleOnMount}
        onChange={handleInputChange}
        value={value}
      ></Editor>
    </>
  );
};

export default RealtimeMonaco;
