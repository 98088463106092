import { Alert, Container, Snackbar, Stack, AlertTitle } from "@mui/material";
import { useMemo, useState } from "react";
import { Outlet } from "react-router";
import Navbar from "./components/Navbar";
import { useMount } from "react-use";
import { Auth } from "aws-amplify";
import { normalizeUser } from "./helpers/normalizeUser";
import useLanguageSupport from "./helpers/useLanguageSupport";
import useAuthStore from "./store/auth";
import useBaseStore from "./store/base";
import { shallow } from "zustand/shallow";

const App = () => {
  const [authInit, setAuthInit] = useState(false); // state to prevent initial render before the authentication is set
  const setUser = useAuthStore((state) => state.setUser);
  const [snackbar, setSnackbar] = useBaseStore(
    (state) => [state.snackbar, state.setSnackbar],
    shallow
  );

  const monacoRegistered = useLanguageSupport(); // register language support once on app load

  useMount(async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();

      if (user) setUser(normalizeUser(user));
    } catch (e) {
      console.log(e);
    }

    setAuthInit(true); // set init to true once we got the current user
  });

  const init = useMemo(() => {
    return monacoRegistered && authInit; // render the app once both the monaco editor and the auth are initialized
  }, [monacoRegistered, authInit]);

  const handleSnackbarClose = () => {
    setSnackbar({
      open: false,
      type: "",
      message: "",
      title: "",
      autoHideDuration: 6000,
    });
  };

  return (
    <Stack sx={{ height: "100vh" }}>
      <Navbar />

      <Container
        maxWidth="100%"
        sx={{
          flexGrow: 1,
          py: 2,
          display: "flex",
          flexDirection: " column",
        }}
      >
        {init ? <Outlet /> : <>Loading...</>}
      </Container>

      {/* have a global snackbar that opens based on global state */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={snackbar.autoHideDuration}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.type ? snackbar.type : undefined}
          sx={{ width: "100%" }}
        >
          {snackbar.title && (
            <AlertTitle>
              {snackbar.title.charAt(0).toUpperCase() + snackbar.title.slice(1)}
            </AlertTitle>
          )}
          {/* only compiler errors have a snackbar title */}

          {snackbar.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default App;
