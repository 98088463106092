import { Stack } from "@mui/material";
import TabTableRow from "./TabTableRow";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { List, AutoSizer } from "react-virtualized";
import { findDOMNode } from "react-dom";
import { OPERATION_ARRAY_REORDER } from "../../../../../helpers/constants";
import useEditorStore from "../../../../../store/editor";
import { shallow } from "zustand/shallow";

const TabTablebody = ({
  rows,
  scrollToIndex,
  hideDragAndDrop,
  tab,
  DeleteRowComponent,
  navigateParams,
  providedOnDrag,
}) => {
  const [surveyDoc, changeEditorSurvey] = useEditorStore(
    (state) => [state.surveyDoc, state.changeEditorSurvey],
    shallow
  );

  const onDrag = (result) => {
    if (hideDragAndDrop) return;

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.source.index === result.destination.index) {
      return;
    }

    const oldIndex = rows[result.source.index].originalIndex;
    const newIndex = rows[result.destination.index].originalIndex;

    const submitOp = () => {
      surveyDoc.submitOp({
        p: [tab, oldIndex],
        lm: newIndex,
      });
    };

    changeEditorSurvey({
      path: [tab],
      newValue: {
        oldIndex,
        newIndex,
      },
      operation: OPERATION_ARRAY_REORDER,
      submitOp,
    });
  };

  return (
    <Stack
      sx={{
        flexGrow: 1,
        border: "1px solid black",
        borderTop: "none",
        borderRadius: "4px",
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
        borderColor: "grey.300",
      }}
    >
      <DragDropContext onDragEnd={providedOnDrag ? providedOnDrag : onDrag}>
        <Droppable
          droppableId="droppable"
          mode="virtual"
          renderClone={(provided, _snapshot, rubric) => (
            <TabTableRow
              row={rows[rubric.source.index]}
              rowIndex={rubric.source.index}
              provided={provided}
              tab={tab}
            />
          )}
        >
          {(droppableProvided) => (
            <AutoSizer>
              {({ width, height }) => (
                <List
                  height={height}
                  rowCount={rows.length}
                  rowHeight={36}
                  width={width}
                  scrollToIndex={scrollToIndex}
                  ref={(ref) => {
                    // react-virtualized has no way to get the list's ref that I can so
                    // So we use the `ReactDOM.findDOMNode(ref)` escape hatch to get the ref
                    if (ref) {
                      // eslint-disable-next-line react/no-find-dom-node
                      const whatHasMyLifeComeTo = findDOMNode(ref);
                      if (whatHasMyLifeComeTo instanceof HTMLElement) {
                        droppableProvided.innerRef(whatHasMyLifeComeTo);
                      }
                    }
                  }}
                  rowRenderer={({ index, key, style }) => (
                    <Draggable
                      draggableId={key}
                      index={index}
                      key={key}
                      style={style}
                      isDragDisabled={hideDragAndDrop}
                    >
                      {(provided) => (
                        <TabTableRow
                          row={rows[index]}
                          rowIndex={rows[index].originalIndex}
                          provided={provided}
                          style={style}
                          hideDragAndDrop={hideDragAndDrop}
                          tab={tab}
                          DeleteRowComponent={DeleteRowComponent}
                          navigateParams={navigateParams}
                        />
                      )}
                    </Draggable>
                  )}
                />
              )}
            </AutoSizer>
          )}
        </Droppable>
      </DragDropContext>
    </Stack>
  );
};

export default TabTablebody;
