import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { API } from "aws-amplify";
import getJwt from "../../../../helpers/getJwt";
import importer from "@slashdata-ltd/survey-tool-compiler-xls/importer";
import useBaseStore from "../../../../store/base";
import transformImporterToShareDB from "../../../../helpers/transformImporterToShareDB";
import useEditorStore from "../../../../store/editor";
import { OPERATION_CHANGE_VALUE } from "../../../../helpers/constants";
import { shallow } from "zustand/shallow";

const ImportFromSpreadsheet = ({ closeDialog }) => {
  const [spreadsheetId, setSpreadsheetId] = useState("");
  const [confirm, setConfirm] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loader, setLoader] = useState(false);
  const setSnackbar = useBaseStore((state) => state.setSnackbar);
  const [surveyDoc, changeEditorSurvey] = useEditorStore(
    (state) => [state.surveyDoc, state.changeEditorSurvey],
    shallow
  );

  const onImport = async () => {
    setLoader(true);

    try {
      const jwt = await getJwt();

      const response = await API.post("editor", "/import", {
        body: {
          sheetId: spreadsheetId,
        },
        headers: { Authorization: `Bearer ${jwt}` },
      });

      const survey = importer(response.allSheets);
      const transformedSurvey = transformImporterToShareDB(survey);

      // console.log("====== SHAREDB ======");
      // console.log(transformedSurvey.questions);
      // console.log(transformedSurvey.questions[3]);

      const submitOp = () => {
        surveyDoc.submitOp([
          {
            p: ["questions"],
            oi: transformedSurvey.questions,
          },
        ]);
      };

      changeEditorSurvey({
        path: ["questions"],
        newValue: transformedSurvey.questions,
        operation: OPERATION_CHANGE_VALUE,
        submitOp,
      });

      setSnackbar({
        open: true,
        type: "success",
        message: "Survey was imported successfully!",
      });
    } catch (e) {
      console.log(e);

      setSnackbar({
        open: true,
        type: "error",
        message: "Error importing the survey",
      });
    }

    setLoader(false);
    closeDialog();
  };

  return (
    <Stack
      sx={{
        flexGrow: 1,
        alignItems: "center",
      }}
      gap={2}
    >
      <Stack>
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          Import from spreadsheet
        </Typography>

        <Alert severity="warning">
          {" "}
          By importing from a spreadsheet, all data will be overwritten.
        </Alert>
      </Stack>

      {showConfirmation ? (
        <>
          <Divider sx={{ alignSelf: "stretch" }} />

          <Typography sx={{ textAlign: "center" }}>
            You are about to import from spreadsheet with ID of{" "}
            <strong>{spreadsheetId}</strong>
          </Typography>

          <TextField
            label='Type "Import" to confirm'
            value={confirm}
            onChange={(e) => {
              setConfirm(e.target.value);
            }}
            size="small"
            sx={{ alignSelf: "stretch" }}
          />

          <LoadingButton
            variant="contained"
            disabled={confirm !== "import"}
            loading={loader}
            onClick={onImport}
            size="small"
          >
            Import from spreadsheet
          </LoadingButton>
        </>
      ) : (
        <>
          <TextField
            label="Spreadsheet ID"
            value={spreadsheetId}
            onChange={(e) => {
              setSpreadsheetId(e.target.value);
            }}
            size="small"
            sx={{ alignSelf: "stretch" }}
          />

          <Button
            variant="contained"
            disabled={!spreadsheetId}
            onClick={() => setShowConfirmation(true)}
            size="small"
          >
            Import
          </Button>
        </>
      )}
    </Stack>
  );
};

export default ImportFromSpreadsheet;
