import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import HeaderWithBackBtn from "../../../../../../components/HeaderWithBackBtn";
import { useParams } from "react-router-dom";
import RealtimeTextField from "../../../../../../components/realtime/RealtimeTextField";
import { ExpandMore } from "@mui/icons-material";
import Questions from "./questions/Questions";
import BlockLogic from "./block-logic/BlockLogic";

const Block = ({ tabItemIndex: blockIndex }) => {
  const { id, tab } = useParams();

  return (
    <Stack
      sx={{
        flex: 1,
      }}
      gap={3}
    >
      <HeaderWithBackBtn
        header={<Typography variant="h6">Edit Block</Typography>}
        redirect={`/surveys/${id}/editor/${tab}`}
      />

      <Stack sx={{ alignSelf: "center", width: 400 }}>
        <RealtimeTextField label="Name" path={["blocks", blockIndex, "name"]} />
      </Stack>

      <Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
            Question Order
          </Typography>
        </AccordionSummary>

        <AccordionDetails sx={{ p: 0 }}>
          <>
            <Divider />

            <Questions blockIndex={blockIndex} />
          </>
        </AccordionDetails>
      </Accordion>

      <Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
            Block logic
          </Typography>
        </AccordionSummary>

        <AccordionDetails sx={{ p: 0 }}>
          <>
            <Divider />

            <BlockLogic blockIndex={blockIndex} />
          </>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

export default Block;
