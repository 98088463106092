import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { changeValueBasedOnPath } from "../helpers/changeValueBasedOnPath";
import {
  OPTIONS_ADD_EDITOR,
  OPTIONS_REMOVE_EDITOR,
  OPTIONS_RESET_EDITOR,
} from "../helpers/constants";

const initialState = {
  worker: null,
  socket: null,
  surveyDoc: null,
  editorSurvey: null,
  editorSurveyInitialized: false,
  optionEditorOpen: [],
  focusedInput: null,
};

const useEditorStore = create()(
  immer((set) => ({
    ...initialState,
    setWorker: (worker) =>
      set((state) => {
        state.worker = worker;
      }),
    setSocket: (socket) =>
      set((state) => {
        state.socket = socket;
      }),
    setSurveyDoc: (surveyDoc) =>
      set((state) => {
        state.surveyDoc = surveyDoc;
      }),
    setEditorSurvey: ({ editorSurvey }) =>
      set((state) => {
        state.editorSurvey = {
          ...editorSurvey,
          // TODO: remove hardcoded properties once implemented
          root: [],
          branches: [],
          versions: [],
          collections: [],
          pages: [],
          triggers: [],
        };
        state.editorSurveyInitialized = true;
      }),
    changeEditorSurvey: ({ path, newValue, operation, submitOp }) => {
      set((state) => {
        changeValueBasedOnPath({
          obj: state.editorSurvey,
          path,
          newValue,
          operation,
        });
        // the changeValueBasedOnPath changes the nested state property based on the provided path.

        submitOp(); // then the operation is submitted to the sharedb
      });
    },
    setOptionEditorOpen: (optionEditorOpen, operation) =>
      set((state) => {
        if (operation === OPTIONS_ADD_EDITOR)
          state.optionEditorOpen.push(optionEditorOpen);

        if (operation === OPTIONS_REMOVE_EDITOR)
          state.optionEditorOpen = state.optionEditorOpen.filter(
            (id) => id !== optionEditorOpen
          );

        if (operation === OPTIONS_RESET_EDITOR) state.optionEditorOpen = [];
      }),
    setFocusedInput: (focusedInput) =>
      set((state) => {
        state.focusedInput = focusedInput;
      }),
    reset: () => set(initialState),
  }))
);

export default useEditorStore;
