import { Close, FilterList, Sort } from "@mui/icons-material";
import { Button, IconButton, Menu, Stack, TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import pluralize from "pluralize";
import { useMemo, useState } from "react";
import NewTabRow from "./NewTabRow";

const TabActions = ({ search, setSearch, setScrollToIndex }) => {
  const { tab } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);

  const singularTab = useMemo(() => {
    return pluralize.singular(tab);
  }, [tab]);

  const onSetSearch = (value) => {
    setSearch({
      ...search,
      [tab]: value,
    });
  };

  return (
    <Stack
      direction="row"
      gap={1}
      sx={{
        mb: 2,
        alignItems: "center",
        alignSelf: "stretch",
      }}
    >
      <TextField
        size="small"
        label={`Search ${singularTab} by name`}
        variant="outlined"
        sx={{ width: "400px" }}
        value={search[tab]}
        onChange={(e) => onSetSearch(e.target.value)}
      />

      {search[tab] && (
        <IconButton
          size="small"
          color="primary"
          onClick={() => onSetSearch("")}
        >
          <Close fontSize="20px" />
        </IconButton>
      )}

      <Button size="small" variant="outlined" startIcon={<FilterList />}>
        Filters
      </Button>

      <Button size="small" variant="outlined" startIcon={<Sort />}>
        Sort
      </Button>

      <Button
        size="small"
        sx={{ ml: "auto" }}
        variant="contained"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        New {singularTab}
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transitionDuration={0}
      >
        <Stack>
          {anchorEl && (
            <NewTabRow
              handleMenuClose={() => setAnchorEl(null)}
              setScrollToIndex={setScrollToIndex}
            />
          )}
        </Stack>
      </Menu>
    </Stack>
  );
};

export default TabActions;
