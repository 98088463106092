import { Add } from "@mui/icons-material";
import { Alert, Button, Dialog, Stack } from "@mui/material";
import TabTableHeader from "../../../tabs/TabTableHeader";
import { useEffect, useMemo, useState } from "react";
import AddQuestions from "./AddQuestions";
import useEditorStore from "../../../../../../../store/editor";
import TabTablebody from "../../../tabs/TabTableBody";
import DeleteQuestion from "./DeleteQuestion";
import { useParams } from "react-router-dom";
import { shallow } from "zustand/shallow";
import { OPERATION_ARRAY_REORDER } from "../../../../../../../helpers/constants";

const Questions = ({ blockIndex }) => {
  const { id } = useParams();
  const [dialog, setDialog] = useState(false);
  const [scrollToIndex, setScrollToIndex] = useState(undefined);

  const [surveyDoc, changeEditorSurvey, blockQuestions, blockId] =
    useEditorStore(
      (state) => [
        state.surveyDoc,
        state.changeEditorSurvey,
        state.editorSurvey.blocks[blockIndex].questions
          .filter((qId) =>
            state.editorSurvey.questions.find((q) => q.id === qId)
          )
          .map((qId, index) => ({
            ...state.editorSurvey.questions.find((q) => q.id === qId),
            originalIndex: index,
          })),
        state.editorSurvey.blocks[blockIndex].id,
      ],
      shallow
    );

  const computedHeight = useMemo(() => {
    return blockQuestions.length * 36 >= 300 ? 300 : blockQuestions.length * 36; // 36 is the row height
  }, [blockQuestions]);

  useEffect(() => {
    if (scrollToIndex)
      setTimeout(() => {
        setScrollToIndex(undefined);
      }, 100);
    // once the scrollToIndex is set and the list scrolled to that index
    // reset the index
  }, [scrollToIndex]);

  const onDrag = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.source.index === result.destination.index) {
      return;
    }

    const oldIndex = blockQuestions[result.source.index].originalIndex;
    const newIndex = blockQuestions[result.destination.index].originalIndex;

    const submitOp = () => {
      surveyDoc.submitOp({
        p: ["blocks", blockIndex, "questions", oldIndex],
        lm: newIndex,
      });
    };

    changeEditorSurvey({
      path: ["blocks", blockIndex, "questions"],
      newValue: {
        oldIndex,
        newIndex,
      },
      operation: OPERATION_ARRAY_REORDER,
      submitOp,
    });
  };

  return (
    <Stack>
      <Stack direction="row" sx={{ p: 2 }} gap={1}>
        <Button
          variant="contained"
          startIcon={<Add />}
          size="small"
          onClick={() => setDialog(true)}
        >
          Add Questions
        </Button>
      </Stack>

      <TabTableHeader tab={"questions"} />

      {!blockQuestions.length && (
        <Alert severity="info">No questions are added</Alert>
      )}

      <Stack sx={{ height: computedHeight }}>
        <TabTablebody
          rows={blockQuestions}
          tab={"questions"}
          navigateParams={(rowId) => ({
            pathname: `/surveys/${id}/editor/questions/${rowId}`,
            search: `?from=blocks&id=${blockId}`,
          })}
          scrollToIndex={scrollToIndex}
          providedOnDrag={onDrag}
          DeleteRowComponent={<DeleteQuestion blockIndex={blockIndex} />}
        />
      </Stack>

      <Dialog onClose={() => setDialog(false)} open={dialog}>
        <Stack
          sx={{
            width: "600px",
            height: "800px",
            overflowX: "hidden",
            pt: 1,
          }}
        >
          <AddQuestions
            onDialogClose={() => setDialog(false)}
            blockIndex={blockIndex}
            setScrollToIndex={setScrollToIndex}
          />
        </Stack>
      </Dialog>
    </Stack>
  );
};

export default Questions;
