import { useEffect, useState } from "react";
import useEditorStore from "../../../../../../../store/editor";
import { shallow } from "zustand/shallow";
import useBaseStore from "../../../../../../../store/base";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { OPERATION_ARRAY_REMOVE } from "../../../../../../../helpers/constants";

const DeleteQuestion = ({ handleMenuClose, row, rowIndex, blockIndex }) => {
  const [confirmText, setConfirmText] = useState("");
  const [initialRow] = useState(row);
  const [surveyDoc, changeEditorSurvey, blockName] = useEditorStore(
    (state) => [
      state.surveyDoc,
      state.changeEditorSurvey,
      state.editorSurvey.blocks[blockIndex].name,
    ],
    shallow
  );
  const setSnackbar = useBaseStore((state) => state.setSnackbar);

  useEffect(() => {
    if (JSON.stringify(initialRow) !== JSON.stringify(row)) {
      handleMenuClose();
    }
  }, [row, initialRow, handleMenuClose]);

  const onDelete = () => {
    const submitOp = () => {
      surveyDoc.submitOp({
        p: ["blocks", blockIndex, "questions", rowIndex],
        ld: row.id,
      });
    };

    changeEditorSurvey({
      path: ["blocks", blockIndex, "questions"],
      newValue: row.id,
      operation: OPERATION_ARRAY_REMOVE,
      submitOp,
    });

    setSnackbar({
      open: true,
      type: "warning",
      message: `Question was deleted from block`,
    });

    handleMenuClose();
  };

  return (
    <Stack gap={1} sx={{ p: 1 }}>
      <Typography variant="body2" sx={{ textAlign: "center" }}>
        Are you sure you want to delete question "<strong>{row?.name}</strong>"
        from block "<strong>{blockName}</strong>"?
      </Typography>

      <TextField
        size="small"
        variant="outlined"
        label="Type delete to confirm"
        value={confirmText}
        onKeyDown={(e) => e.stopPropagation()}
        onChange={(e) => setConfirmText(e.target.value)}
      />

      <Stack direction="row" gap={1} sx={{ justifyContent: "center" }}>
        <Button size="small" onClick={handleMenuClose}>
          Cancel
        </Button>

        <Button
          size="small"
          color="error"
          variant="contained"
          disabled={confirmText !== "delete"}
          onClick={onDelete}
        >
          Delete
        </Button>
      </Stack>
    </Stack>
  );
};

export default DeleteQuestion;
