import { Navigate, useLocation } from "react-router-dom";
import useAuthStore from "../store/auth";

// this component wraps the route elements and either returns the children, or navigates away.
const AuthGuard = ({ children, requiresAuth, allowedRoles }) => {
  const user = useAuthStore((state) => state.user);
  const roles = user?.groups || []; // user.groups are coming from cognito.
  let location = useLocation();

  if (requiresAuth) {
    if (!user) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    let allowed = roles.some((role) => allowedRoles.includes(role));

    if (!allowed)
      return <Navigate to="/unauthorized" state={{ from: "guard" }} replace />;
  } else {
    if (user) {
      return <Navigate to="/" replace />;
    }
  }

  return children;
};

export default AuthGuard;
