import AuthGuard from "./AuthGuard";
import Surveys from "../views/surveys/Surveys";
import CreateSurvey from "../views/surveys/CreateSurvey";
import EditSurvey from "../views/surveys/edit-survey/EditSurvey";
import General from "../views/surveys/edit-survey/General";
import Editor from "../views/surveys/edit-survey/editor/Editor";
import Tabs from "../views/surveys/edit-survey/editor/tabs/Tabs";
import { Navigate } from "react-router-dom";
import TabItem from "../views/surveys/edit-survey/editor/tab-item/TabItem";

const routes = [
  {
    path: "/surveys",
    element: (
      <AuthGuard requiresAuth allowedRoles={["admin"]}>
        <Surveys />
      </AuthGuard>
    ),
  },
  {
    path: "/surveys/create",
    element: (
      <AuthGuard requiresAuth allowedRoles={["admin"]}>
        <CreateSurvey />
      </AuthGuard>
    ),
  },
  {
    path: "/surveys/:id",
    element: (
      <AuthGuard requiresAuth allowedRoles={["admin"]}>
        <EditSurvey />
      </AuthGuard>
    ),
    children: [
      // child routes do not require a guard, as the parent guard is applied.
      {
        index: true,
        element: <General />,
      },
      {
        path: "editor",
        element: <Editor />,
        children: [
          {
            element: <Navigate to="questions" replace={true} />,
            index: true,
          },
          {
            path: ":tab",
            element: <Tabs />,
          },
          {
            path: ":tab/:itemId",
            element: <TabItem />,
          },
        ],
      },
    ],
  },
];

export default routes;
