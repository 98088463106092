import { Alert, Button, Divider, Stack, Typography } from "@mui/material";
import OptionRow from "./OptionRow";
import useEditorStore from "../../../../../../../store/editor";
import { useEffect, useMemo, useRef, useState } from "react";
import { Add, PlaylistAdd } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import { generateLogicName } from "../../../../../../../helpers/generateLogicName";
import { shallow } from "zustand/shallow";
import { compareArrays } from "../../../../../../../helpers/compareArrays";
import { List, AutoSizer, CellMeasurer } from "react-virtualized";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { findDOMNode } from "react-dom";
import { createCellMeasurerCache } from "../../../../../../../helpers/createCellMeasurerCache";
import { useUnmount } from "react-use";
import { showFlags } from "../../../../../../../helpers/showFlags";
import {
  OPTIONS_ADD_EDITOR,
  FLAG_ALL,
  OPERATION_ARRAY_INSERT,
  OPERATION_ARRAY_REORDER,
  FLAG_EXCLUSIVE,
  FLAG_FIXED,
  OPTION_TYPE_IMPORT,
  OPTION_TYPE_TEXT,
  FLAG_UNIQUE,
  FLAG_WRITE_IN,
} from "../../../../../../../helpers/constants";

const Options = ({ questionIndex, selector }) => {
  const cacheRef = useRef(null);
  if (!cacheRef.current) {
    cacheRef.current = createCellMeasurerCache(56); // create a new cache each time this component is first rendered
  }
  const [scrollToIndex, setScrollToIndex] = useState(0);

  const [
    surveyDoc,
    changeEditorSurvey,
    setOptionEditorOpen,
    optionEditorOpen,
    questionType,
  ] = useEditorStore(
    (state) => [
      state.surveyDoc,
      state.changeEditorSurvey,
      state.setOptionEditorOpen,
      state.optionEditorOpen,
      state.editorSurvey.questions[questionIndex].type,
    ],
    shallow
  );

  const options = useEditorStore(
    (state) => state.editorSurvey.questions[questionIndex][selector],
    compareArrays
  );

  const computedHeight = useMemo(() => {
    const totalHeight =
      (options.length - optionEditorOpen.length) * 57 +
      optionEditorOpen.length * 257; // normal row height is 57 and with open editor is 257.

    return Math.min(totalHeight, 500);
    // max height is 500
  }, [optionEditorOpen, options]);

  const flags = useMemo(() => {
    return showFlags(questionType, selector); // each time questionType changes, recalculate the flags based on the selector.
  }, [questionType, selector]);

  useEffect(() => {
    const focusedInput = useEditorStore.getState().focusedInput; // get state directly from store in order to prevent triggering useffect based on that prop

    if (focusedInput) {
      const index = options.findIndex(
        (opt) => opt.id === focusedInput.optionId
      );

      if (index > -1) setScrollToIndex(index);
      // scroll to where the focusedInput is
      // if the user is focused in an input and a remote user drags it in a position that needs scrolling to be reached
    }
  }, [options]);

  useEffect(() => {
    if (scrollToIndex)
      setTimeout(() => {
        setScrollToIndex(undefined);
      }, 100);
    // once the scrollToIndex is set and the list scrolled to that index
    // reset the index
  }, [scrollToIndex]);

  useUnmount(() => {
    cacheRef.current.clearAll(); // clear cache on unmount
  });

  const onDrag = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.source.index === result.destination.index) {
      return;
    }

    const oldIndex = result.source.index;
    const newIndex = result.destination.index;

    const submitOp = () => {
      surveyDoc.submitOp({
        p: ["questions", questionIndex, selector, oldIndex],
        lm: newIndex,
      });
    };

    changeEditorSurvey({
      path: ["questions", questionIndex, selector],
      newValue: {
        oldIndex,
        newIndex,
      },
      operation: OPERATION_ARRAY_REORDER,
      submitOp,
    });
  };

  const onNewOption = (type) => {
    const id = uuidv4();

    const newOption =
      type === OPTION_TYPE_TEXT
        ? {
            id,
            text: "",
            type: OPTION_TYPE_TEXT,
            alias: "",
            unique: false,
            exclusive: false,
            fixed: false,
            writeIn: false,
            displayLogic: null,
          } // new option initial object
        : {
            id,
            type: OPTION_TYPE_IMPORT,
            name: generateLogicName({
              allLogicDefinitions: options,
              type,
              part: selector,
            }),
            logic: "",
          }; // import decorator initial object

    const submitOp = () => {
      surveyDoc.submitOp([
        {
          p: ["questions", questionIndex, selector, options.length],
          li: newOption,
        },
      ]);
    };

    changeEditorSurvey({
      path: ["questions", questionIndex, selector],
      newValue: newOption,
      operation: OPERATION_ARRAY_INSERT,
      submitOp,
    });

    if (type === OPTION_TYPE_IMPORT)
      setOptionEditorOpen(id, OPTIONS_ADD_EDITOR);

    setScrollToIndex(options.length);
  };

  return (
    <Stack>
      <Stack direction="row" sx={{ p: 2 }} gap={1}>
        <Button
          variant="contained"
          startIcon={<Add />}
          size="small"
          onClick={() => onNewOption(OPTION_TYPE_TEXT)}
        >
          Native
        </Button>

        <Button
          variant="contained"
          startIcon={<PlaylistAdd />}
          size="small"
          onClick={() => onNewOption(OPTION_TYPE_IMPORT)}
        >
          Import
        </Button>
      </Stack>

      {/* ====== HEADER ====== */}
      <Stack gap={1} sx={{ mb: 1 }}>
        <Stack direction="row" gap={1} sx={{ px: 2 }}>
          <Stack sx={{ flex: 1 }}></Stack>

          <Stack sx={{ flex: 2 }}>
            <Typography variant="body2" sx={{ fontWeight: "500" }}>
              Text
            </Typography>
          </Stack>

          <Stack sx={{ flex: 2 }}>
            <Typography variant="body2" sx={{ fontWeight: "500" }}>
              Alias
            </Typography>
          </Stack>

          {flags.includes(FLAG_UNIQUE) && (
            <Stack sx={{ flex: 1 }}>
              <Typography variant="body2" sx={{ fontWeight: "500" }}>
                Unique
              </Typography>
            </Stack>
          )}

          {flags.includes(FLAG_EXCLUSIVE) && (
            <Stack sx={{ flex: 1 }}>
              <Typography variant="body2" sx={{ fontWeight: "500" }}>
                Exclusive
              </Typography>
            </Stack>
          )}

          {flags.includes(FLAG_FIXED) && (
            <Stack sx={{ flex: 1 }}>
              <Typography variant="body2" sx={{ fontWeight: "500" }}>
                Fixed
              </Typography>
            </Stack>
          )}

          {flags.includes(FLAG_ALL) && (
            <Stack sx={{ flex: 1 }}>
              <Typography variant="body2" sx={{ fontWeight: "500" }}>
                All
              </Typography>
            </Stack>
          )}

          {flags.includes(FLAG_WRITE_IN) && (
            <Stack sx={{ flex: 1 }}>
              <Typography variant="body2" sx={{ fontWeight: "500" }}>
                Write In
              </Typography>
            </Stack>
          )}

          <Stack sx={{ flex: 2 }}>
            <Typography variant="body2" sx={{ fontWeight: "500" }}>
              Logic
            </Typography>
          </Stack>

          <Stack sx={{ flex: 1 }}></Stack>
        </Stack>

        <Divider />
      </Stack>

      {options.length ? (
        <Stack
          sx={{
            height: computedHeight,
          }}
        >
          <DragDropContext onDragEnd={onDrag}>
            <Droppable
              droppableId="droppable"
              mode="virtual"
              renderClone={(provided, snapshot, rubric) => (
                <OptionRow
                  questionIndex={questionIndex}
                  selector={selector}
                  index={rubric.source.index}
                  isLast={rubric.source.index === options.length - 1}
                  isDragging={snapshot.isDragging}
                  provided={provided}
                />
              )}
            >
              {(droppableProvided) => (
                <AutoSizer>
                  {({ width, height }) => (
                    <List
                      width={width}
                      height={height}
                      rowHeight={cacheRef.current.rowHeight}
                      deferredMeasurementCache={cacheRef.current}
                      rowCount={options.length}
                      scrollToIndex={scrollToIndex}
                      ref={(ref) => {
                        // react-virtualized has no way to get the list's ref that I can so
                        // So we use the `ReactDOM.findDOMNode(ref)` escape hatch to get the ref
                        if (ref) {
                          // eslint-disable-next-line react/no-find-dom-node
                          const foundRef = findDOMNode(ref);
                          if (foundRef instanceof HTMLElement) {
                            droppableProvided.innerRef(foundRef);
                          }
                        }
                      }}
                      rowRenderer={({ index, key, style, parent }) => (
                        <Draggable
                          draggableId={key}
                          index={index}
                          key={key}
                          style={style}
                        >
                          {(provided) => (
                            <CellMeasurer
                              key={key}
                              cache={cacheRef.current}
                              parent={parent}
                              columnIndex={0}
                              rowIndex={index}
                            >
                              {({ measure }) => (
                                <OptionRow
                                  questionIndex={questionIndex}
                                  selector={selector}
                                  index={index}
                                  isLast={index === options.length - 1}
                                  provided={provided}
                                  style={style}
                                  measure={measure}
                                  setScrollToIndex={setScrollToIndex}
                                />
                              )}
                            </CellMeasurer>
                          )}
                        </Draggable>
                      )}
                    />
                  )}
                </AutoSizer>
              )}
            </Droppable>
          </DragDropContext>
        </Stack>
      ) : (
        <Stack sx={{ p: 2 }}>
          <Alert severity="info">No {selector} are added</Alert>
        </Stack>
      )}
    </Stack>
  );
};

export default Options;
