import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import HeaderWithBackBtn from "../../../../../../components/HeaderWithBackBtn";
import { useParams, useSearchParams } from "react-router-dom";
import RealtimeTextField from "../../../../../../components/realtime/RealtimeTextField";
import { ExpandMore } from "@mui/icons-material";
import Options from "./options/Options";
import QuestionLogic from "./question-logic/QuestionLogic";
import Collaborators from "../../../../../../components/Collaborators";
import RealtimeSelect from "../../../../../../components/realtime/RealtimeSelect";
import RealtimeCheckbox from "../../../../../../components/realtime/RealtimeCheckbox";
import useEditorStore from "../../../../../../store/editor";
import { useMemo } from "react";
import { useUnmount } from "react-use";
import { shallow } from "zustand/shallow";
import {
  GROUPS,
  OPTIONS,
  OPTIONS_RESET_EDITOR,
} from "../../../../../../helpers/constants";

const Question = ({ tabItemIndex: questionIndex }) => {
  const { id, tab } = useParams();
  let [searchParams] = useSearchParams();

  const [questionType, setOptionEditorOpen] = useEditorStore(
    (state) => [
      state.editorSurvey.questions[questionIndex].type,
      state.setOptionEditorOpen,
    ],
    shallow
  );

  const hasOptions = useMemo(() => {
    return /(_LIST|_GRID)$/.test(questionType);
  }, [questionType]);

  const hasGroups = useMemo(() => {
    return /_GRID$/.test(questionType);
  }, [questionType]);

  useUnmount(() => {
    setOptionEditorOpen([], OPTIONS_RESET_EDITOR); // close all open editors when leaving the question
  });

  const redirectURL = useMemo(() => {
    const { from, id: queryParamId } = Object.fromEntries([...searchParams]);

    if (from && queryParamId) {
      return `/surveys/${id}/editor/${from}/${queryParamId}`;
    }

    return `/surveys/${id}/editor/${tab}`;
  }, [id, tab, searchParams]);

  return (
    <Stack
      sx={{
        flex: 1,
      }}
      gap={3}
    >
      <HeaderWithBackBtn
        header={<Typography variant="h6">Edit Question</Typography>}
        redirect={redirectURL}
      />

      <Collaborators />

      <Stack direction="row" gap={2}>
        <Stack gap={2} sx={{ flex: 1 }}>
          <RealtimeSelect
            label="Type"
            path={["questions", questionIndex, "type"]}
            options={[
              { value: "SEARCHABLE_LIST", label: "Searchable List" },
              { value: "RADIO_LIST", label: "Radio List" },
              { value: "RADIO_GRID", label: "Radio Grid" },
              { value: "CHECKBOX_LIST", label: "Checkbox List" },
              { value: "CHECKBOX_GRID", label: "Checkbox Grid" },
              { value: "TEXT", label: "Text" },
              { value: "TEXTAREA", label: "Textarea" },
              { value: "RATING_LIST", label: "Rating List" },
              { value: "RATING_GRID", label: "Rating Grid" },
              { value: "READONLY", label: "Readonly" },
            ]}
          />

          <RealtimeTextField
            label="Name"
            path={["questions", questionIndex, "name"]}
          />
        </Stack>

        <Stack gap={2} sx={{ flex: 1 }}>
          <RealtimeTextField
            label="Title"
            path={["questions", questionIndex, "title"]}
            multiline
          />

          <RealtimeTextField
            label="Instructional Text"
            path={["questions", questionIndex, "description"]}
            multiline
          />

          <RealtimeTextField
            label="Content"
            path={["questions", questionIndex, "content"]}
            multiline
          />
        </Stack>

        <Stack gap={2} sx={{ flex: 1 }}>
          {hasOptions && (
            <RealtimeTextField
              label="Max Allowed Options"
              path={["questions", questionIndex, "maxOptionsAllowed"]}
              type="number"
            />
          )}

          {hasGroups && (
            <RealtimeTextField
              label="Max Allowed Groups"
              path={["questions", questionIndex, "maxGroupsAllowed"]}
              type="number"
            />
          )}

          {hasOptions && (
            <RealtimeCheckbox
              label="Randomized Options"
              path={["questions", questionIndex, "optionsRandomized"]}
            />
          )}

          {hasGroups && (
            <RealtimeCheckbox
              label="Randomized Groups"
              path={["questions", questionIndex, "groupsRandomized"]}
            />
          )}
        </Stack>
      </Stack>

      {hasOptions && (
        <Accordion TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
              Options
            </Typography>
          </AccordionSummary>

          <AccordionDetails sx={{ p: 0 }}>
            <>
              <Divider />

              <Options questionIndex={questionIndex} selector={OPTIONS} />
            </>
          </AccordionDetails>
        </Accordion>
      )}

      {hasGroups && (
        <Accordion TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
              Groups
            </Typography>
          </AccordionSummary>

          <AccordionDetails sx={{ p: 0 }}>
            <>
              <Divider />

              <Options questionIndex={questionIndex} selector={GROUPS} />
            </>
          </AccordionDetails>
        </Accordion>
      )}

      <Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
            Question Logic
          </Typography>
        </AccordionSummary>

        <AccordionDetails sx={{ p: 0 }}>
          <>
            <Divider />

            <QuestionLogic questionIndex={questionIndex} />
          </>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

export default Question;
