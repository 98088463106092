import { Button, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import useEditorStore from "../../../../../../../store/editor";
import { shallow } from "zustand/shallow";
import { useUpdateEffect } from "react-use";
import {
  OPERATION_ARRAY_REMOVE,
  OPTION_TYPE_TEXT,
} from "../../../../../../../helpers/constants";

const DeleteOption = ({ handleClose, index, questionIndex, selector }) => {
  const [surveyDoc, changeEditorSurvey, option] = useEditorStore(
    (state) => [
      state.surveyDoc,
      state.changeEditorSurvey,
      state.editorSurvey.questions[questionIndex][selector][index],
    ],
    shallow
  );

  useUpdateEffect(() => {
    handleClose();
  }, [option?.id, handleClose]);

  const onDelete = () => {
    const submitOp = () => {
      surveyDoc.submitOp({
        p: ["questions", questionIndex, selector, index],
        ld: option,
      });
    };

    changeEditorSurvey({
      path: ["questions", questionIndex, selector],
      newValue: option,
      operation: OPERATION_ARRAY_REMOVE,
      submitOp,
    });

    handleClose();
  };

  const name = useMemo(() => {
    return option.type === OPTION_TYPE_TEXT
      ? `${option.text}`
      : `${option.name}`;
  }, [option]);

  return (
    <Stack gap={1} sx={{ p: 1 }}>
      <Typography variant="body2" sx={{ textAlign: "center" }}>
        Are you sure you want to delete{" "}
        {option.type === OPTION_TYPE_TEXT
          ? selector.slice(0, -1)
          : "import logic"}{" "}
        "<strong>{name}</strong>" ?
      </Typography>

      <Stack direction="row" gap={1} sx={{ justifyContent: "center" }}>
        <Button size="small" onClick={handleClose}>
          Cancel
        </Button>

        <Button
          size="small"
          color="error"
          variant="contained"
          onClick={() => onDelete()}
        >
          Delete
        </Button>
      </Stack>
    </Stack>
  );
};

export default DeleteOption;
