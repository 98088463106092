import { Box, Stack, Tooltip } from "@mui/material";

const Collaborators = () => {
  return (
    <Stack direction="row" gap={1} sx={{ justifyContent: "center" }}>
      <Tooltip title="giannis.savvidis@slashdata.co" placement="top-start">
        <Box
          component="img"
          src={require("../assets/giannis.jpg")}
          sx={{
            height: "30px",
            width: "30px",
            borderRadius: "50%",
            objectFit: "cover",
            cursor: "pointer",
          }}
        />
      </Tooltip>

      <Tooltip title="davidc@slashdata.co" placement="top-start">
        <Box
          component="img"
          src={require("../assets/david.jpg")}
          sx={{
            height: "30px",
            width: "30px",
            borderRadius: "50%",
            objectFit: "cover",
            cursor: "pointer",
          }}
        />
      </Tooltip>

      <Tooltip title="alex@slashdata.co" placement="top-start">
        <Box
          component="img"
          src={require("../assets/alex.png")}
          sx={{
            height: "30px",
            width: "30px",
            borderRadius: "50%",
            objectFit: "cover",
            cursor: "pointer",
          }}
        />
      </Tooltip>
    </Stack>
  );
};

export default Collaborators;
