import { Button, Stack, Typography } from "@mui/material";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../store/auth";
import useEditorStore from "../store/editor";
import useSurveysStore from "../store/surveys";

const LogoutConfirmation = ({ onClose }) => {
  const navigate = useNavigate();
  const setUser = useAuthStore((state) => state.setUser);
  const editorReset = useEditorStore((state) => state.reset);
  const surveysReset = useSurveysStore((state) => state.reset);

  const handleLogout = async () => {
    try {
      await Auth.signOut();

      // clear all stores on logout
      setUser(null);
      editorReset();
      surveysReset();
      navigate("/login");
      onClose();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  return (
    <Stack
      sx={{
        flexGrow: 1,
        alignItems: "center",
      }}
      gap={2}
    >
      <Typography variant="h6" sx={{ textAlign: "center" }}>
        Logout from Survey Editor?
      </Typography>

      <Stack direction="row" gap={1}>
        <Button size="small" onClick={onClose}>
          Cancel
        </Button>

        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={handleLogout}
        >
          Yes, Logout
        </Button>
      </Stack>
    </Stack>
  );
};

export default LogoutConfirmation;
