import { get } from "lodash";

// extracts the fields of interest from the cognito current user object. stored in the global state.
export const normalizeUser = (cognitoUser) => {
  const userId = get(cognitoUser, "username", "");
  const email = get(
    cognitoUser,
    ["signInUserSession", "idToken", "payload", "email"],
    ""
  );
  const groups = get(
    cognitoUser,
    ["signInUserSession", "idToken", "payload", "cognito:groups"],
    []
  );

  return {
    userId,
    email,
    groups,
  };
};
