import {
  OPERATION_ARRAY_INSERT,
  OPERATION_ARRAY_REMOVE,
  OPERATION_ARRAY_REORDER,
  OPERATION_CHANGE_VALUE,
} from "./constants";

// helper function for internal use. Moves an item in an array.
const _reorder = (list, oldIndex, newIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(oldIndex, 1);
  result.splice(newIndex, 0, removed);

  return result;
};

// changes a nested value of an object based on the provided path
// supports multiple operations that also depend to the type of the nested value we are changing (array | object)
const changeValueBasedOnPath = ({ obj, path, newValue, operation }) => {
  let currentObj = obj;

  // Iterate through each element in the path array except the last one
  for (let i = 0; i < path.length - 1; i++) {
    const key = path[i];

    // Traverse into the object based on the current path element
    if (currentObj.hasOwnProperty(key)) {
      currentObj = currentObj[key];
    } else {
      // If the path is invalid, return or handle the error accordingly
      return;
    }
  }

  const lastKey = path[path.length - 1];

  if (operation === OPERATION_CHANGE_VALUE) {
    const oldValue = currentObj[lastKey];

    // Assign the new value to the targeted property
    currentObj[lastKey] = newValue;

    return oldValue;
  }

  if (operation === OPERATION_ARRAY_INSERT) {
    currentObj[lastKey].push(newValue);
  }

  if (operation === OPERATION_ARRAY_REMOVE) {
    currentObj[lastKey] = currentObj[lastKey].filter(
      (item) => JSON.stringify(item) !== JSON.stringify(newValue)
    );
  }

  if (operation === OPERATION_ARRAY_REORDER) {
    // with this type of operation, the newValue is an object with oldIndex and newIndex properties
    currentObj[lastKey] = _reorder(
      currentObj[lastKey],
      newValue.oldIndex,
      newValue.newIndex
    );
  }
};

export { changeValueBasedOnPath };
