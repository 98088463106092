import { Add } from "@mui/icons-material";
import { Alert, Button, Divider, Stack } from "@mui/material";
import useEditorStore from "../../../../../../../store/editor";
import { shallow } from "zustand/shallow";
import { v4 as uuidv4 } from "uuid";
import { generateLogicName } from "../../../../../../../helpers/generateLogicName";
import {
  BLOCK,
  OPERATION_ARRAY_INSERT,
} from "../../../../../../../helpers/constants";
import BlockLogicRow from "./BlockLogicRow";
import { compareArrays } from "../../../../../../../helpers/compareArrays";

const BlockLogic = ({ blockIndex }) => {
  const [surveyDoc, changeEditorSurvey] = useEditorStore(
    (state) => [state.surveyDoc, state.changeEditorSurvey],
    shallow
  );

  const logicDefinitions = useEditorStore(
    (state) => state.editorSurvey.blocks[blockIndex].logicDefinitions,
    compareArrays
  );

  const addBlockLogic = () => {
    const newLogicDefinition = {
      id: uuidv4(),
      type: "display",
      name: generateLogicName({
        allLogicDefinitions: logicDefinitions,
        type: "display",
        part: BLOCK,
      }),
      logic: "",
    };

    const submitOp = () => {
      surveyDoc.submitOp([
        {
          p: [
            "blocks",
            blockIndex,
            "logicDefinitions",
            logicDefinitions.length,
          ],
          li: newLogicDefinition,
        },
      ]);
    };

    changeEditorSurvey({
      path: ["blocks", blockIndex, "logicDefinitions"],
      newValue: newLogicDefinition,
      operation: OPERATION_ARRAY_INSERT,
      submitOp,
    });
  };

  return (
    <Stack>
      <Stack direction="row" sx={{ p: 2 }} gap={1}>
        <Button
          variant="contained"
          startIcon={<Add />}
          size="small"
          onClick={addBlockLogic}
        >
          Add display logic
        </Button>
      </Stack>

      <Divider />

      {!logicDefinitions.length && (
        <Alert severity="info">No block logic is added</Alert>
      )}

      {Boolean(logicDefinitions.length) && (
        <>
          {logicDefinitions.map((ld, index) => (
            <BlockLogicRow blockIndex={blockIndex} index={index} key={ld.id} />
          ))}
        </>
      )}
    </Stack>
  );
};

export default BlockLogic;
