import { Stack, Typography } from "@mui/material";
import { useState } from "react";
import { API } from "aws-amplify";
import { useNavigate } from "react-router";
import HeaderWithBackBtn from "../../components/HeaderWithBackBtn";
import getJwt from "../../helpers/getJwt";
import dayjs from "dayjs";
import useSurveysStore from "../../store/surveys";
import useBaseStore from "../../store/base";
import SurveyForm from "./SurveyForm";
import { LoadingButton } from "@mui/lab";
import { shallow } from "zustand/shallow";

const CreateSurvey = () => {
  const navigate = useNavigate();
  const [surveys, setSurveys] = useSurveysStore(
    (state) => [state.surveys, state.setSurveys],
    shallow
  );
  const setSnackbar = useBaseStore((state) => state.setSnackbar);
  const [name, setName] = useState("");
  const [type, setType] = useState("public");
  const [startDate, setStartDate] = useState(dayjs.utc().startOf("day"));
  const [endDate, setEndDate] = useState(
    dayjs.utc().startOf("day").add(3, "month")
  );
  const [loader, setLoader] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoader(true);

    try {
      const jwt = await getJwt();

      const res = await API.post("surveys", "/surveys", {
        body: {
          name,
          type,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
        headers: { Authorization: `Bearer ${jwt}` },
      });

      setSurveys([...surveys, res]); // add new survey to state in order to prevent re-fetch

      setSnackbar({
        open: true,
        type: "success",
        message: "Survey Created!",
      });

      navigate(`/surveys/${res._id}`);
    } catch (e) {
      console.error(e.response);

      setSnackbar({
        open: true,
        type: "error",
        message: "Something went wrong",
      });
    }

    setLoader(false);
  };

  return (
    <Stack
      sx={{
        flexGrow: 1,
        alignItems: "center",
      }}
      gap={4}
    >
      <HeaderWithBackBtn
        header={<Typography variant="h6">Create Survey</Typography>}
        redirect="/surveys"
      />

      <Stack
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ width: "500px" }}
        gap={2}
      >
        <SurveyForm
          name={name}
          setName={setName}
          type={type}
          setType={setType}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />

        <LoadingButton
          loading={loader}
          variant="contained"
          type="submit"
          disabled={!name || !type || !startDate || !endDate}
        >
          Create Survey
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default CreateSurvey;
