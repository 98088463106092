import { Divider, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import tabColumns from "./tabColumns";

const TabTableHeader = ({ tab, hideDragAndDrop }) => {
  const columns = useMemo(() => {
    return tabColumns[tab];
  }, [tab]);

  return (
    <Stack
      sx={{
        border: "1px solid black",
        borderBottom: "none",
        borderRadius: "4px",
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
        borderColor: "grey.300",
      }}
    >
      <Stack
        direction="row"
        gap={1}
        sx={{ px: 2, height: 36, alignItems: "center" }}
      >
        {!hideDragAndDrop && <Stack sx={{ flex: 1 }}></Stack>}

        {columns.map((column, index) => (
          <Stack sx={{ flex: 2 }} key={index}>
            <Typography variant="subtitle2">{column.label}</Typography>
          </Stack>
        ))}

        <Stack sx={{ flex: 1 }}></Stack>
      </Stack>

      <Divider />
    </Stack>
  );
};

export default TabTableHeader;
