import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import useEditorStore from "../../../../../store/editor";
import { Stack } from "@mui/material";
import Question from "./question/Question";
import { compareArrays } from "../../../../../helpers/compareArrays";
import Block from "./block/Block";

const TabItem = () => {
  const { id, itemId, tab } = useParams();
  const [tabItemIndex, setTabItemIndex] = useState(-1);
  const editorSurveyTab = useEditorStore(
    (state) => state.editorSurvey[tab],
    compareArrays
  );
  const navigate = useNavigate();

  useEffect(() => {
    // ====== ROUTE GUARD ======
    // used in case the user directly navigates from the browser bar.
    // but also when the editorSurvey changes remotely
    // hence the useffect instead of the use mount

    const index = editorSurveyTab?.findIndex((item) => item.id === itemId);
    if (index === -1) return navigate(`/surveys/${id}/editor/${tab}`);

    setTabItemIndex(index);
    // ====== END ROUTE GUARD ======
  }, [editorSurveyTab, id, itemId, navigate, tab]);

  const allowRender = useMemo(() => {
    return tabItemIndex > -1;
  }, [tabItemIndex]);

  return (
    <Stack
      sx={{
        flexGrow: 1,
      }}
    >
      {allowRender && (
        <>
          {tab === "questions" && <Question tabItemIndex={tabItemIndex} />}
          {tab === "blocks" && <Block tabItemIndex={tabItemIndex} />}
        </>
      )}
    </Stack>
  );
};

export default TabItem;
