import { Button, Stack, Typography } from "@mui/material";
import useEditorStore from "../../../../../../../store/editor";
import { shallow } from "zustand/shallow";
import { useUpdateEffect } from "react-use";
import {
  OPERATION_CHANGE_VALUE,
  OPTIONS_REMOVE_EDITOR,
} from "../../../../../../../helpers/constants";

const DeleteDisplayLogic = ({
  handleClose,
  index,
  questionIndex,
  selector,
}) => {
  const [
    surveyDoc,
    changeEditorSurvey,
    option,
    optionEditorOpen,
    setOptionEditorOpen,
  ] = useEditorStore(
    (state) => [
      state.surveyDoc,
      state.changeEditorSurvey,
      state.editorSurvey.questions[questionIndex][selector][index],
      state.optionEditorOpen,
      state.setOptionEditorOpen,
    ],
    shallow
  );

  useUpdateEffect(() => {
    handleClose();
  }, [option?.id, handleClose]);

  const onDelete = () => {
    const submitOp = () => {
      surveyDoc.submitOp({
        p: ["questions", questionIndex, selector, index, "displayLogic"],
        od: option.displayLogic,
        oi: null,
      });
    };

    changeEditorSurvey({
      path: ["questions", questionIndex, selector, index, "displayLogic"],
      newValue: null,
      operation: OPERATION_CHANGE_VALUE,
      submitOp,
    });

    if (optionEditorOpen.includes(option.id))
      setOptionEditorOpen(option.id, OPTIONS_REMOVE_EDITOR);

    handleClose();
  };

  return (
    <Stack gap={1} sx={{ p: 1 }}>
      <Typography variant="body2" sx={{ textAlign: "center" }}>
        Are you sure you want to delete <strong>display logic</strong> of{" "}
        {selector.slice(0, -1)} "<strong>{option.text}</strong>" ?
      </Typography>

      <Stack direction="row" gap={1} sx={{ justifyContent: "center" }}>
        <Button size="small" onClick={handleClose}>
          Cancel
        </Button>

        <Button
          size="small"
          color="error"
          variant="contained"
          onClick={() => onDelete()}
        >
          Delete
        </Button>
      </Stack>
    </Stack>
  );
};

export default DeleteDisplayLogic;
