import { Navigate, createBrowserRouter } from "react-router-dom";
import AuthGuard from "./AuthGuard";

import App from "../App";
import Login from "../views/Login";
import Unauthorized from "../views/Unauthorized";
import surveysRoutes from "./surveys";

export default createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: "/login",
        element: (
          <AuthGuard requiresAuth={false}>
            <Login />
          </AuthGuard>
        ),
      },
      // ============ SURVEYS ============
      ...surveysRoutes, // have survey related routes in another file
      // add another category of routes like "themes" later on, in order to keep things separated
      {
        path: "/unauthorized",
        element: <Unauthorized />,
      },
      {
        path: "*",
        element: <Navigate to="/surveys" />,
      },
    ],
  },
]);
