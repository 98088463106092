import { Fragment, useMemo, useState } from "react";
import useEditorStore from "../../../../../../../store/editor";
import { v4 as uuidv4 } from "uuid";
import {
  Alert,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import QuestionLogicCard from "./QuestionLogicCard";
import { generateLogicName } from "../../../../../../../helpers/generateLogicName";
import { shallow } from "zustand/shallow";
import { compareArrays } from "../../../../../../../helpers/compareArrays";
import {
  OPERATION_ARRAY_INSERT,
  QUESTION,
} from "../../../../../../../helpers/constants";

const QuestionLogic = ({ questionIndex }) => {
  const [surveyDoc, changeEditorSurvey] = useEditorStore(
    (state) => [state.surveyDoc, state.changeEditorSurvey],
    shallow
  );
  const logicDefinitions = useEditorStore(
    (state) => state.editorSurvey.questions[questionIndex].logicDefinitions,
    compareArrays
  );
  const [type, setType] = useState("");

  const logicDefinitionsCategories = useMemo(() => {
    const categories = {
      display: [],
      required: [],
      variable: [],
      unique: [],
    };

    logicDefinitions.forEach((ld) => {
      categories[ld.type].push(ld);
    });

    // Remove properties with empty arrays
    Object.keys(categories).forEach((key) => {
      if (categories[key].length === 0) {
        delete categories[key];
      }
    });

    return categories;
  }, [logicDefinitions]);

  const onNewQuestionLogic = () => {
    const newLogicDefinition = {
      id: uuidv4(),
      type,
      name: generateLogicName({
        allLogicDefinitions: logicDefinitions,
        type,
        part: QUESTION,
      }),
      logic: "",
    };

    const submitOp = () => {
      surveyDoc.submitOp([
        {
          p: [
            "questions",
            questionIndex,
            "logicDefinitions",
            logicDefinitions.length,
          ],
          li: newLogicDefinition,
        },
      ]);
    };

    changeEditorSurvey({
      path: ["questions", questionIndex, "logicDefinitions"],
      newValue: newLogicDefinition,
      operation: OPERATION_ARRAY_INSERT,
      submitOp,
    });

    setType("");
  };

  return (
    <Stack gap={3} sx={{ maxHeight: "500px", overflowY: "auto", p: 2 }}>
      <Stack direction="row" gap={1} sx={{ alignItems: "center" }}>
        {type && (
          <IconButton size="small" onClick={() => setType("")}>
            <Close fontSize="20px" />
          </IconButton>
        )}

        <FormControl size="small" sx={{ width: 200 }}>
          <InputLabel>Type</InputLabel>

          <Select
            value={type}
            label="Type"
            onChange={(e) => setType(e.target.value)}
          >
            <MenuItem value={"display"}>Display</MenuItem>
            <MenuItem value={"required"}>Required</MenuItem>
            <MenuItem value={"variable"}>Variable</MenuItem>
            <MenuItem value={"unique"}>Unique</MenuItem>
          </Select>
        </FormControl>

        <Button
          variant="contained"
          size="small"
          onClick={onNewQuestionLogic}
          disabled={!type}
        >
          Add
        </Button>
      </Stack>

      <Stack gap={3}>
        {logicDefinitions.length ? (
          <>
            {Object.keys(logicDefinitionsCategories).map(
              // for each of the categories that have at least one logicDefinition
              (questionLogicCategory) => {
                return (
                  <Stack key={questionLogicCategory} gap={2}>
                    <Typography
                      variant="body1"
                      sx={{ textTransform: "capitalize", fontWeight: 500 }}
                    >
                      {questionLogicCategory} Logic
                    </Typography>

                    {logicDefinitions.map((questionLogic, index) => {
                      // run a loop for all logic definitions so the index is correct,
                      // instead of directly loop the logicDefinitionsCategories[questionLogicCategory]

                      return questionLogic.type === questionLogicCategory ? (
                        <QuestionLogicCard
                          key={questionLogic.id}
                          questionIndex={questionIndex}
                          index={index}
                          type={questionLogic.type}
                        />
                      ) : (
                        <Fragment key={questionLogic.id}></Fragment>
                      );
                    })}
                  </Stack>
                );
              }
            )}
          </>
        ) : (
          <Alert severity="info">No question logic is added</Alert>
        )}
      </Stack>
    </Stack>
  );
};

export default QuestionLogic;
