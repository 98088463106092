import { Auth } from "aws-amplify";

// gets the jwt from amplify. It is always refreshed.
const getJwt = async () => {
  const user = await Auth.currentAuthenticatedUser();

  return user.signInUserSession.idToken.jwtToken;
};

export default getJwt;
