import { CellMeasurerCache } from "react-virtualized";

// helper function for creating a CellMeasurerCache.
// It is used for providing the heights of each row in a virtualized list.
// each time a list is rendered, a new cache should be created,
// else there is a mismatch on expanded rows of other lists.

export const createCellMeasurerCache = (defaultHeight) => {
  return new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight,
  });
};
