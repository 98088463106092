import { Alert, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useMount } from "react-use";
import useEditorStore from "../../../../../store/editor";
import TabActions from "./TabActions";
import TabTableHeader from "./TabTableHeader";
import TabTablebody from "./TabTableBody";

const Tabs = () => {
  const { id, tab } = useParams();
  const navigate = useNavigate();
  const [guardCheck, setGuardCheck] = useState(false);
  const [search, setSearch] = useState({
    questions: "",
    blocks: "",
    collections: "",
    versions: "",
    branches: "",
    root: "",
    pages: "",
    triggers: "",
  });
  const tabRows = useEditorStore((state) => state.editorSurvey[tab]);
  const [scrollToIndex, setScrollToIndex] = useState(undefined);

  useMount(() => {
    // ====== ROUTE GUARD ======
    const tabFound = useEditorStore.getState().editorSurvey[tab];

    // used in case the user directly navigates from the browser bar.
    if (!tabFound) navigate(`/surveys/${id}/editor`);

    setGuardCheck(true); // render the component only after the check is complete, to prevent errors

    // ====== END ROUTE GUARD ======
  });

  const filteredTabRows = useMemo(() => {
    if (!guardCheck) return [];

    if (!search[tab])
      return tabRows.map((row, originalIndex) => ({ ...row, originalIndex }));

    return (
      tabRows
        .map((row, originalIndex) => ({ ...row, originalIndex }))
        // keep the original index because index will be different when filtered
        // and we want the original index in order to delete the correct element
        .filter((row) =>
          row.name.toLowerCase().includes(search[tab].toLowerCase())
        )
    );
  }, [tabRows, search, tab, guardCheck]);

  useEffect(() => {
    if (scrollToIndex)
      setTimeout(() => {
        setScrollToIndex(undefined);
      }, 100);
    // once the scrollToIndex is set and the list scrolled to that index
    // reset the index
  }, [scrollToIndex]);

  return (
    <Stack
      sx={{
        flexGrow: 1,
      }}
    >
      {guardCheck && (
        <>
          <TabActions
            setSearch={setSearch}
            search={search}
            setScrollToIndex={setScrollToIndex}
          />

          <TabTableHeader tab={tab} />

          {!search[tab] && !tabRows.length && (
            <Alert severity="info">No {tab} are added</Alert>
          )}

          {search[tab] && !filteredTabRows.length && (
            <Alert severity="warning">No {tab} found</Alert>
          )}

          <TabTablebody
            rows={filteredTabRows}
            scrollToIndex={scrollToIndex}
            tab={tab}
          />
        </>
      )}
    </Stack>
  );
};

export default Tabs;
