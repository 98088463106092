import { useState } from "react";
import useEditorStore from "../../../../../../../store/editor";
import {
  Chip,
  Collapse,
  Divider,
  IconButton,
  Menu,
  Stack,
  Typography,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import RealtimeMonaco from "../../../../../../../components/realtime/RealtimeMonaco";
import DeleteBlockLogic from "./DeleteBlockLogic";

const BlockLogicRow = ({ blockIndex, index }) => {
  const blockLogicName = useEditorStore(
    (state) =>
      state.editorSurvey.blocks[blockIndex].logicDefinitions[index].name
  );
  const [codeOpen, setCodeOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack>
      <Stack direction="row" sx={{ alignItems: "center", p: 2 }} gap={1}>
        <Chip
          variant="outlined"
          color="primary"
          label={
            <Typography variant="body2" sx={{ fontWeight: 500 }}>
              {blockLogicName}
            </Typography>
          }
          clickable
          onClick={() => setCodeOpen(!codeOpen)}
        />

        <IconButton
          size="small"
          color="error"
          sx={{ ml: "auto" }}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <Delete fontSize="20px" />
        </IconButton>
      </Stack>

      <Collapse in={codeOpen} timeout={0} unmountOnExit role="button">
        <RealtimeMonaco
          path={["blocks", blockIndex, "logicDefinitions", index, "logic"]}
        />
      </Collapse>

      <Divider />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transitionDuration={0}
      >
        <DeleteBlockLogic
          blockIndex={blockIndex}
          index={index}
          handleMenuClose={handleMenuClose}
        />
      </Menu>
    </Stack>
  );
};

export default BlockLogicRow;
