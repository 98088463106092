import {
  AppBar,
  Button,
  Dialog,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import useAuthStore from "../store/auth";
import { useState } from "react";
import LogoutConfirmation from "./LogoutConfirmation";

const Navbar = () => {
  const user = useAuthStore((state) => state.user);
  const [dialog, setDialog] = useState(false);

  const handleDialogClose = () => {
    setDialog(false);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Stack
          sx={{ flexGrow: 1, alignItems: "center" }}
          gap={1}
          direction="row"
        >
          <Typography
            component={NavLink}
            to="/"
            variant="h6"
            sx={{
              color: "inherit",
              textDecoration: "none",
              mr: "auto",
            }}
          >
            Slash Data | Survey Editor
          </Typography>

          {!user && (
            <Button color="inherit" component={NavLink} to="/login">
              Login
            </Button>
          )}

          {user && (
            <>
              {!user.unauthorized && (
                <Button
                  component={NavLink}
                  to="/surveys"
                  color="inherit"
                  sx={{
                    "&.active": {
                      backgroundColor: "rgba(255, 255, 255, 0.15)",
                    },
                  }}
                >
                  Surveys
                </Button>
              )}

              <Button color="inherit" onClick={() => setDialog(true)}>
                Logout
              </Button>
            </>
          )}
        </Stack>
      </Toolbar>

      <Dialog onClose={handleDialogClose} open={dialog}>
        <Stack sx={{ width: "600px", padding: 2 }}>
          <LogoutConfirmation onClose={handleDialogClose} />
        </Stack>
      </Dialog>
    </AppBar>
  );
};

export default Navbar;
