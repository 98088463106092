import { Alert, AlertTitle, Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useMount } from "react-use";
import useAuthStore from "../store/auth";
import { shallow } from "zustand/shallow";

const Unauthorized = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [setUser, user] = useAuthStore(
    (state) => [state.setUser, state.user],
    shallow
  );

  useMount(() => {
    if (location?.state?.from !== "guard") return navigate("/"); // if someone navigate in this route manually, then redirect them to "/" route

    setUser({
      ...user,
      unauthorized: true,
    }); // if they arrived here from the guard, meaning they are unauthorized, add the respective flag to the user object
  });

  return (
    <Stack
      sx={{
        flexGrow: 1,
        alignItems: "center",
      }}
    >
      <Alert severity="warning">
        <AlertTitle>Unauthorized</AlertTitle>
        This account does not have admin privileges. Once admin privileges are
        applied, please logout and login again.
      </Alert>
    </Stack>
  );
};

export default Unauthorized;
