import useEditorStore from "../../store/editor";
import jsondiff from "json0-ot-diff";
import diffMatchPatch from "diff-match-patch";
import { TextField } from "@mui/material";
import { shallow } from "zustand/shallow";
import { getValueBasedOnPath } from "../../helpers/getValueBasedOnPath";
import {
  OPERATION_CHANGE_VALUE,
  OPTION_TYPE_TEXT,
} from "../../helpers/constants";
import { useEffect, useMemo, useRef } from "react";

// index and optionId props are passed only from the options component. else will be just undefined
const RealtimeTextField = ({
  label,
  path,
  multiline,
  type = OPTION_TYPE_TEXT,
  optionId,
  index,
}) => {
  const inputRef = useRef(null);
  const value = useEditorStore((state) =>
    getValueBasedOnPath(state.editorSurvey, path)
  );
  const inputKey = useMemo(() => path[path.length - 1], [path]);

  const [surveyDoc, changeEditorSurvey, focusedInput, setFocusedInput] =
    useEditorStore(
      (state) => [
        state.surveyDoc,
        state.changeEditorSurvey,
        state.focusedInput,
        state.setFocusedInput,
      ],
      shallow
    );

  useEffect(() => {
    if (
      focusedInput?.optionId === optionId &&
      focusedInput?.inputKey === inputKey &&
      focusedInput?.index !== index
    ) {
      // if focusedInput optionId and inputKey are the same as this component, but different index
      // which means the remote user has reordered the list
      // then focus this component
      inputRef.current.focus();
    }
  }, [index, optionId, inputKey, focusedInput]);

  const handleFocus = () => {
    setFocusedInput({
      optionId,
      inputKey,
      index,
    });
  };

  const handleBlur = () => {
    setFocusedInput(null);
  };

  const handleInputChange = (e) => {
    let newValue = e.target.value;

    if (type === "number") {
      newValue = parseInt(newValue);

      if (isNaN(newValue) || newValue < 1) {
        newValue = "";
      }
    }

    let diff = jsondiff(value, newValue, diffMatchPatch);

    diff = diff.map((d) => {
      return {
        ...d,
        p: [...path, ...d.p],
      };
    });

    const submitOp = () => {
      surveyDoc.submitOp(diff);
    };

    changeEditorSurvey({
      path,
      newValue,
      operation: OPERATION_CHANGE_VALUE,
      submitOp,
    });
  };

  return (
    <TextField
      label={label}
      variant="outlined"
      value={value}
      onChange={handleInputChange}
      size="small"
      sx={{ width: "100%" }}
      multiline={multiline}
      rows={3}
      type={type}
      onFocus={handleFocus}
      onBlur={handleBlur}
      inputRef={inputRef}
    />
  );
};

export default RealtimeTextField;
