// provided as a third argument in a zustand selector as custom equality function.
// used in pieces of state that are arrays
// the goal is to rerender the component if the array is changed (reorder, add, remove items)
// but not if a nested property is changed.

const compareArrays = (oldArray, newArray) => {
  if (oldArray.length !== newArray.length) {
    //an array item was added or removed
    return false;
  }

  for (let i = 0; i < oldArray.length; i++) {
    if (oldArray[i].id !== newArray[i].id) {
      // and array was reorder... with drag and drop
      return false;
    }
  }

  // do not rerender
  return true;
};

export { compareArrays };
