import { Code, CodeOff, Delete, DragHandle } from "@mui/icons-material";
import {
  Chip,
  Collapse,
  Divider,
  IconButton,
  Menu,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import RealtimeTextField from "../../../../../../../components/realtime/RealtimeTextField";
import RealtimeCheckbox from "../../../../../../../components/realtime/RealtimeCheckbox";
import { useEffect, useMemo, useState } from "react";
import DeleteOption from "./DeleteOption";
import useEditorStore from "../../../../../../../store/editor";
import { v4 as uuidv4 } from "uuid";
import DeleteDisplayLogic from "./DeleteDisplayLogic";
import { generateLogicName } from "../../../../../../../helpers/generateLogicName";
import { shallow } from "zustand/shallow";
import { useMount, useUpdateEffect } from "react-use";
import RealtimeMonaco from "../../../../../../../components/realtime/RealtimeMonaco";
import { showFlags } from "../../../../../../../helpers/showFlags";
import {
  OPTIONS_ADD_EDITOR,
  FLAG_ALL,
  OPERATION_CHANGE_VALUE,
  LOGIC_TYPE_DISPLAY,
  FLAG_EXCLUSIVE,
  FLAG_FIXED,
  OPTION_TYPE_IMPORT,
  OPTIONS_REMOVE_EDITOR,
  OPTION_TYPE_TEXT,
  FLAG_UNIQUE,
  FLAG_WRITE_IN,
} from "../../../../../../../helpers/constants";

const getStyle = (provided, style) => {
  if (!style) {
    return provided.draggableProps.style;
  }

  return {
    ...provided.draggableProps.style,
    ...style,
  };
};

const MENU_DELETE = "DELETE";
const MENU_DELETE_DISPLAY = "DELETE_DISPLAY";

const OptionRow = ({
  questionIndex,
  selector,
  index,
  isLast,
  isDragging,
  provided,
  style,
  measure,
  setScrollToIndex,
}) => {
  const [menu, setMenu] = useState({
    anchorEl: null,
    component: null, // could be MENU_DELETE or MENU_DELETE_DISPLAY
  });
  const [
    surveyDoc,
    changeEditorSurvey,
    setOptionEditorOpen,
    optionType,
    optionId,
    optionName,
    questionType,
  ] = useEditorStore(
    (state) => [
      state.surveyDoc,
      state.changeEditorSurvey,
      state.setOptionEditorOpen,
      state.editorSurvey.questions[questionIndex][selector][index].type,
      state.editorSurvey.questions[questionIndex][selector][index].id,
      state.editorSurvey.questions[questionIndex][selector][index].name,
      state.editorSurvey.questions[questionIndex].type,
    ],
    shallow
  );
  const optionEditorOpen = useEditorStore(
    (state) => state.optionEditorOpen,
    (oldValue, newValue) => {
      if (oldValue.includes(optionId) || newValue.includes(optionId))
        // rerender only if either new or old value includes optionId
        return false;

      return true;
    }
  );
  const optionDisplayLogic = useEditorStore(
    (state) =>
      state.editorSurvey.questions[questionIndex][selector][index].displayLogic,
    (oldValue, newValue) => {
      if (!(oldValue && newValue) || (oldValue && !newValue)) return false;
      // rerender only if new value was null and oldValue is not or vice versa.

      // do not rerender if the contents of display logic changed.
      return true;
    }
  );

  useEffect(() => {
    if (
      optionType === OPTION_TYPE_TEXT && // if option is text
      optionEditorOpen.includes(optionId) && // and has the editor open (meaning its refering to display logic)
      !optionDisplayLogic // but the display logic is null, meaning it remotely removed.
    )
      setOptionEditorOpen(optionId, OPTIONS_REMOVE_EDITOR); // close the editor
  }, [
    optionDisplayLogic,
    optionEditorOpen,
    optionId,
    setOptionEditorOpen,
    optionType,
  ]);

  useMount(() => {
    if (isDragging) return; // if true then it means this component is the clone

    const hasOpenEditor = optionEditorOpen.includes(optionId);

    if (hasOpenEditor || (style?.height > 57 && !hasOpenEditor)) measure();
    // recalculate the row height if the row has an open editor or if it used to have an open editor and it was dragged in another index
  });

  useUpdateEffect(() => {
    setTimeout(() => {
      try {
        measure(); // recalculate row height when editor opens
      } catch (e) {
        console.log(e);
      }
    }, 1);
  }, [optionEditorOpen, optionId, measure, index]);

  const open = useMemo(() => {
    return Boolean(menu.anchorEl);
  }, [menu]);

  const codePath = useMemo(() => {
    if (optionEditorOpen.includes(optionId)) {
      if (optionType === OPTION_TYPE_TEXT)
        return [
          "questions",
          questionIndex,
          selector,
          index,
          "displayLogic",
          "logic",
        ];

      if (optionType === OPTION_TYPE_IMPORT)
        return ["questions", questionIndex, selector, index, "logic"];
    }

    return [];
  }, [optionEditorOpen, optionId, optionType, questionIndex, index, selector]);

  const flags = useMemo(() => {
    return showFlags(questionType, selector); // each time questionType changes, recalculate the flags based on the selector.
  }, [questionType, selector]);

  const handleOpen = (event, component) => {
    setMenu({
      anchorEl: event.currentTarget,
      component,
    });
  };

  const handleClose = () => {
    setMenu({
      anchorEl: null,
      component: null,
    });
  };

  const onAddDisplayLogic = () => {
    const options =
      useEditorStore.getState().editorSurvey.questions[questionIndex][selector];

    const newDisplayLogic = {
      id: uuidv4(),
      type: LOGIC_TYPE_DISPLAY,
      name: generateLogicName({
        allLogicDefinitions: options,
        type: LOGIC_TYPE_DISPLAY,
        part: selector,
      }),
      logic: "",
    };

    const submitOp = () => {
      surveyDoc.submitOp([
        {
          p: ["questions", questionIndex, selector, index, "displayLogic"],
          oi: newDisplayLogic,
        },
      ]);
    };

    changeEditorSurvey({
      path: ["questions", questionIndex, selector, index, "displayLogic"],
      newValue: newDisplayLogic,
      operation: OPERATION_CHANGE_VALUE,
      submitOp,
    });

    setOptionEditorOpen(optionId, OPTIONS_ADD_EDITOR);
  };

  const onSetCodeOpen = () => {
    if (optionEditorOpen.includes(optionId))
      return setOptionEditorOpen(optionId, OPTIONS_REMOVE_EDITOR);

    setOptionEditorOpen(optionId, OPTIONS_ADD_EDITOR);

    setScrollToIndex(index);
  };

  return (
    <Stack
      {...provided.draggableProps}
      ref={provided.innerRef}
      style={getStyle(provided, style)}
    >
      <Stack direction="row" sx={{ py: 1, px: 2, height: 56 }} gap={1}>
        {/* ====== DRAG AND DROP HANDLE ====== */}
        <Stack sx={{ flex: 1, justifyContent: "center", alignItems: "start" }}>
          <Stack {...provided.dragHandleProps}>
            <DragHandle />
          </Stack>
        </Stack>

        {/* ====== TEXT ====== */}
        <Stack sx={{ flex: 2, justifyContent: "center", alignItems: "start" }}>
          {optionType === OPTION_TYPE_TEXT ? (
            <RealtimeTextField
              label="Text"
              path={["questions", questionIndex, selector, index, "text"]}
              optionId={optionId}
              index={index}
            />
          ) : (
            "-"
          )}
        </Stack>

        {/* ====== ALIAS ====== */}
        <Stack sx={{ flex: 2, justifyContent: "center", alignItems: "start" }}>
          {optionType === OPTION_TYPE_TEXT ? (
            <RealtimeTextField
              label="Alias"
              path={["questions", questionIndex, selector, index, "alias"]}
            />
          ) : (
            "-"
          )}
        </Stack>

        {/* ====== UNIQUE ====== */}
        {flags.includes(FLAG_UNIQUE) && (
          <Stack
            sx={{ flex: 1, justifyContent: "center", alignItems: "start" }}
          >
            {optionType === OPTION_TYPE_TEXT ? (
              <RealtimeCheckbox
                path={["questions", questionIndex, selector, index, "unique"]}
              />
            ) : (
              "-"
            )}
          </Stack>
        )}

        {/* ====== EXCLUSIVE ====== */}
        {flags.includes(FLAG_EXCLUSIVE) && (
          <Stack
            sx={{ flex: 1, justifyContent: "center", alignItems: "start" }}
          >
            {optionType === OPTION_TYPE_TEXT ? (
              <RealtimeCheckbox
                path={[
                  "questions",
                  questionIndex,
                  selector,
                  index,
                  "exclusive",
                ]}
              />
            ) : (
              "-"
            )}
          </Stack>
        )}

        {/* ====== FIXED ====== */}
        {flags.includes(FLAG_FIXED) && (
          <Stack
            sx={{ flex: 1, justifyContent: "center", alignItems: "start" }}
          >
            {optionType === OPTION_TYPE_TEXT ? (
              <RealtimeCheckbox
                path={["questions", questionIndex, selector, index, "fixed"]}
              />
            ) : (
              "-"
            )}
          </Stack>
        )}

        {/* ====== ALL ====== */}
        {flags.includes(FLAG_ALL) && (
          <Stack
            sx={{ flex: 1, justifyContent: "center", alignItems: "start" }}
          >
            {optionType === OPTION_TYPE_TEXT ? (
              <RealtimeCheckbox
                path={["questions", questionIndex, selector, index, "all"]}
              />
            ) : (
              "-"
            )}
          </Stack>
        )}

        {/* ====== WRITE IN ====== */}
        {flags.includes(FLAG_WRITE_IN) && (
          <Stack
            sx={{ flex: 1, justifyContent: "center", alignItems: "start" }}
          >
            {optionType === OPTION_TYPE_TEXT ? (
              <RealtimeCheckbox
                path={["questions", questionIndex, selector, index, "writeIn"]}
              />
            ) : (
              "-"
            )}
          </Stack>
        )}

        {/* ====== LOGIC ====== */}
        <Stack sx={{ flex: 2, justifyContent: "center", alignItems: "start" }}>
          {optionType === OPTION_TYPE_TEXT ? (
            <>
              {optionDisplayLogic ? (
                <Chip
                  variant="outlined"
                  color="primary"
                  label={
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      {optionDisplayLogic.name}
                    </Typography>
                  }
                  clickable
                  onClick={onSetCodeOpen}
                />
              ) : (
                "-"
              )}
            </>
          ) : (
            <Chip
              variant="outlined"
              color="primary"
              label={
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  {optionName}
                </Typography>
              }
              clickable
              onClick={onSetCodeOpen}
            />
          )}
        </Stack>

        <Stack
          sx={{ flex: 1, justifyContent: "end", alignItems: "center" }}
          direction="row"
          gap={1}
        >
          {optionType === OPTION_TYPE_TEXT && (
            <>
              {optionDisplayLogic ? (
                <Tooltip title="Remove display logic" placement="top-start">
                  <IconButton
                    size="small"
                    color="error"
                    onClick={(e) => handleOpen(e, MENU_DELETE_DISPLAY)}
                  >
                    <CodeOff fontSize="20px" />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Add display logic" placement="top-start">
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={onAddDisplayLogic}
                  >
                    <Code fontSize="20px" />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}

          <IconButton
            size="small"
            color="error"
            onClick={(e) => handleOpen(e, MENU_DELETE)}
          >
            <Delete fontSize="20px" />
          </IconButton>
        </Stack>
      </Stack>

      <Collapse
        in={optionEditorOpen.includes(optionId)}
        timeout={0}
        unmountOnExit
        role="button"
      >
        <RealtimeMonaco path={codePath} optionId={optionId} index={index} />
      </Collapse>

      {!isLast && <Divider />}

      <Menu
        anchorEl={menu.anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {menu.component === MENU_DELETE && (
          <DeleteOption
            handleClose={handleClose}
            questionIndex={questionIndex}
            selector={selector}
            index={index}
          />
        )}

        {menu.component === MENU_DELETE_DISPLAY && (
          <DeleteDisplayLogic
            handleClose={handleClose}
            questionIndex={questionIndex}
            selector={selector}
            index={index}
          />
        )}
      </Menu>
    </Stack>
  );
};

export default OptionRow;
