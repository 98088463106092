import { Stack, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { API } from "aws-amplify";
import getJwt from "../../../helpers/getJwt";
import useSurveysStore from "../../../store/surveys";
import useBaseStore from "../../../store/base";
import SurveyForm from "../SurveyForm";
import { LoadingButton } from "@mui/lab";
import { shallow } from "zustand/shallow";

const General = () => {
  const [surveys, currentSurvey, setCurrentSurvey, setSurveys] =
    useSurveysStore(
      (state) => [
        state.surveys,
        state.currentSurvey,
        state.setCurrentSurvey,
        state.setSurveys,
      ],
      shallow
    );
  const setSnackbar = useBaseStore((state) => state.setSnackbar);
  const [name, setName] = useState("");
  const [type, setType] = useState("public");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loader, setLoader] = useState(false);
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    if (currentSurvey) {
      setName(currentSurvey.name);
      setType(currentSurvey.type);
      setStartDate(dayjs.utc(currentSurvey.startDate));
      setEndDate(dayjs.utc(currentSurvey.endDate));

      setInitialValues({
        name: currentSurvey.name,
        type: currentSurvey.type,
        startDate: dayjs.utc(currentSurvey.startDate),
        endDate: dayjs.utc(currentSurvey.endDate),
      });
    }
  }, [currentSurvey]);

  const hasChanges = useMemo(() => {
    return (
      JSON.stringify(initialValues) !==
      JSON.stringify({
        name,
        type,
        startDate: dayjs.utc(startDate),
        endDate: dayjs.utc(endDate),
      })
    );
  }, [initialValues, name, type, startDate, endDate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoader(true);

    try {
      const jwt = await getJwt();

      await API.put("surveys", `/surveys/${currentSurvey._id}`, {
        body: {
          name,
          type,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
        headers: { Authorization: `Bearer ${jwt}` },
      });

      const index = surveys.findIndex((s) => s._id === currentSurvey._id);

      setCurrentSurvey({
        ...currentSurvey,
        name,
        type,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      }); // update current survey state

      setSurveys([
        ...surveys.slice(0, index),
        {
          ...currentSurvey,
          name,
          type,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
        ...surveys.slice(index + 1),
      ]); // update the survey in the surveys array

      setSnackbar({
        open: true,
        type: "success",
        message: "Survey updated!",
      });
    } catch (e) {
      console.error(e.response);

      setSnackbar({
        open: true,
        type: "error",
        message: "Something went wrong",
      });
    }

    setLoader(false);
  };

  return (
    <Stack
      sx={{
        flexGrow: 1,
        alignItems: "center",
      }}
      gap={4}
    >
      <Typography variant="h6">Edit Survey</Typography>

      <Stack
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ width: "500px" }}
        gap={2}
      >
        <SurveyForm
          name={name}
          setName={setName}
          type={type}
          setType={setType}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />

        <LoadingButton
          loading={loader}
          variant="contained"
          type="submit"
          disabled={!name || !type || !startDate || !endDate || !hasChanges}
        >
          Edit Survey
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default General;
