import { Delete } from "@mui/icons-material";
import {
  Chip,
  Collapse,
  Divider,
  IconButton,
  Menu,
  Stack,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import DeleteQuestionlogic from "./DeleteQuestionlogic";
import useEditorStore from "../../../../../../../store/editor";
import RealtimeMonaco from "../../../../../../../components/realtime/RealtimeMonaco";

const QuestionLogicCard = ({ index, questionIndex }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const questionLogicName = useEditorStore(
    (state) =>
      state.editorSurvey.questions[questionIndex].logicDefinitions[index].name
  );
  const [codeOpen, setCodeOpen] = useState(false);

  const open = useMemo(() => {
    return Boolean(anchorEl);
  }, [anchorEl]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack gap={2}>
      <Stack direction="row" sx={{ alignItems: "center" }} gap={1}>
        <Chip
          variant="outlined"
          color="primary"
          label={
            <Typography variant="body2" sx={{ fontWeight: 500 }}>
              {questionLogicName}
            </Typography>
          }
          clickable
          onClick={() => setCodeOpen(!codeOpen)}
        />

        <IconButton
          size="small"
          color="error"
          sx={{ ml: "auto" }}
          onClick={(e) => handleOpen(e)}
        >
          <Delete fontSize="20px" />
        </IconButton>
      </Stack>

      <Collapse in={codeOpen} timeout={0} unmountOnExit role="button">
        <RealtimeMonaco
          path={[
            "questions",
            questionIndex,
            "logicDefinitions",
            index,
            "logic",
          ]}
        />
      </Collapse>

      <Divider />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <DeleteQuestionlogic
          questionIndex={questionIndex}
          index={index}
          handleClose={handleClose}
        />
      </Menu>
    </Stack>
  );
};

export default QuestionLogicCard;
