export const FLAG_UNIQUE = "unique";
export const FLAG_FIXED = "fixed";
export const FLAG_EXCLUSIVE = "exclusive";
export const FLAG_WRITE_IN = "writeIn";
export const FLAG_ALL = "all";

export const OPTIONS_ADD_EDITOR = "OPTIONS_ADD_EDITOR";
export const OPTIONS_REMOVE_EDITOR = "OPTIONS_REMOVE_EDITOR";
export const OPTIONS_RESET_EDITOR = "OPTIONS_RESET_EDITOR";

export const OPTION_TYPE_TEXT = "text";
export const OPTION_TYPE_IMPORT = "import";

export const OPERATION_CHANGE_VALUE = "CHANGE_VALUE";
export const OPERATION_ARRAY_INSERT = "ARRAY_INSERT";
export const OPERATION_ARRAY_REMOVE = "ARRAY_REMOVE";
export const OPERATION_ARRAY_REORDER = "ARRAY_REORDER";

export const QUESTION = "question";
export const OPTIONS = "options";
export const GROUPS = "groups";
export const BLOCK = "block";

export const LOGIC_TYPE_DISPLAY = "display";

export const FLAGS_DICTIONARY = {
  f: FLAG_FIXED,
  e: FLAG_EXCLUSIVE,
  w: FLAG_WRITE_IN,
  u: FLAG_UNIQUE,
  a: FLAG_ALL,
};
