import { Checkbox, FormControlLabel } from "@mui/material";
import useEditorStore from "../../store/editor";
import { shallow } from "zustand/shallow";
import { getValueBasedOnPath } from "../../helpers/getValueBasedOnPath";
import { OPERATION_CHANGE_VALUE } from "../../helpers/constants";

const RealtimeCheckbox = ({ path, label, disabled }) => {
  const value = useEditorStore((state) =>
    getValueBasedOnPath(state.editorSurvey, path)
  );
  const [surveyDoc, changeEditorSurvey] = useEditorStore(
    (state) => [state.surveyDoc, state.changeEditorSurvey],
    shallow
  );

  const handleInputChange = (e) => {
    const submitOp = () => {
      surveyDoc.submitOp({
        p: path,
        od: value,
        oi: e.target.checked,
      });
    };

    changeEditorSurvey({
      path,
      newValue: e.target.checked,
      operation: OPERATION_CHANGE_VALUE,
      submitOp,
    });
  };

  return label ? (
    <FormControlLabel
      control={<Checkbox size="small" />}
      label={label}
      checked={disabled ? false : value}
      onChange={handleInputChange}
      disabled={disabled}
    />
  ) : (
    <Checkbox size="small" checked={value} onChange={handleInputChange} />
  );
};

export default RealtimeCheckbox;
