import { shallow } from "zustand/shallow";
import useEditorStore from "../../../../../../../store/editor";
import { OPERATION_ARRAY_REMOVE } from "../../../../../../../helpers/constants";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";

const DeleteBlockLogic = ({ blockIndex, index, handleMenuClose }) => {
  const [surveyDoc, changeEditorSurvey, blockLogic] = useEditorStore(
    (state) => [
      state.surveyDoc,
      state.changeEditorSurvey,
      state.editorSurvey.blocks[blockIndex].logicDefinitions[index],
    ],
    shallow
  );
  const [confirmText, setConfirmText] = useState("");

  const onDelete = () => {
    const submitOp = () => {
      surveyDoc.submitOp({
        p: ["blocks", blockIndex, "logicDefinitions", index],
        ld: blockLogic,
      });
    };

    changeEditorSurvey({
      path: ["blocks", blockIndex, "logicDefinitions"],
      newValue: blockLogic,
      operation: OPERATION_ARRAY_REMOVE,
      submitOp,
    });

    handleMenuClose();
  };

  return (
    <Stack gap={1} sx={{ p: 1 }}>
      <Typography variant="body2" sx={{ textAlign: "center" }}>
        Are you sure you want to delete block logic "
        <strong>{blockLogic.name}</strong>"?
      </Typography>

      <TextField
        size="small"
        variant="outlined"
        label="Type delete to confirm"
        value={confirmText}
        onKeyDown={(e) => e.stopPropagation()}
        onChange={(e) => setConfirmText(e.target.value)}
      />

      <Stack direction="row" gap={1} sx={{ justifyContent: "center" }}>
        <Button size="small" onClick={handleMenuClose}>
          Cancel
        </Button>

        <Button
          size="small"
          color="error"
          variant="contained"
          disabled={confirmText !== "delete"}
          onClick={onDelete}
        >
          Delete
        </Button>
      </Stack>
    </Stack>
  );
};

export default DeleteBlockLogic;
