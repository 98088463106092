import { ArrowBackIosNew } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  useTheme,
} from "@mui/material";
import {
  Link,
  NavLink,
  Outlet,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useMount, useUnmount } from "react-use";
import useSurveysStore from "../../../store/surveys";
import { useState } from "react";
import useEditorStore from "../../../store/editor";
import workerize from "workerize-loader!../../../helpers/worker"; // eslint-disable-line import/no-webpack-loader-syntax
import useBaseStore from "../../../store/base";
import { shallow } from "zustand/shallow";

const EditSurvey = () => {
  const [loader, setLoader] = useState(true);
  const [fetchSurveys, setCurrentSurvey] = useSurveysStore(
    (state) => [state.fetchSurveys, state.setCurrentSurvey],
    shallow
  );
  const [setWorker, worker, reset, socket, surveyDoc] = useEditorStore(
    (state) => [
      state.setWorker,
      state.worker,
      state.reset,
      state.socket,
      state.surveyDoc,
    ],
    shallow
  );
  const setSnackbar = useBaseStore((state) => state.setSnackbar);
  const theme = useTheme();

  const { id } = useParams();
  const navigate = useNavigate();

  useMount(async () => {
    reset(); // each time entering the edit survey component, reset the editor store

    const instance = workerize(); // create a worker
    setWorker(instance); // save worker to the state

    instance.onmessage = (event) => {
      if (event.data.name === "CompilerError") {
        console.log(event.data);

        if (event.data.cause)
          setSnackbar({
            open: true,
            type: "error",
            message: event.data.cause
              ? `Cause: ${event.data.cause.name} - ${event.data.cause.value}`
              : event.data.message,
            title: event.data.scope
              ? `${event.data.scope.name}: ${event.data.scope.value}`
              : "",
          });
      }
    }; // this is a worker on message listener.
    // worker sends a message only in case of error.
    // the listener used to just open a snackbar

    // ====== ROUTE GUARD ======
    // used in case the user directly navigates from the browser bar.

    let surveys;

    try {
      surveys = await fetchSurveys(); // gets surveys, if already fetched, just retruns the surveys from the store.
    } catch (e) {
      console.log(e);

      surveys = [];
    }

    const found = surveys.find((survey) => survey._id === id);

    if (!found) navigate("/"); // if a survey with the provided id is not found then navigate away.

    setCurrentSurvey(found);

    // ====== END ROUTE GUARD ======

    setLoader(false);
  });

  useUnmount(() => {
    worker.terminate(); // terminate worker
    socket?.close(); // close websocket
    surveyDoc?.unsubscribe(); // unsubscribe from sharedb document

    setCurrentSurvey(null);

    // ...when leaving the route
  });

  return (
    <Stack
      sx={{
        flexGrow: 1,
      }}
      direction="row"
    >
      {loader ? (
        <CircularProgress thickness={2} size="80px" sx={{ m: "auto" }} />
      ) : (
        <>
          <Stack
            sx={{
              width: "250px",
              backgroundColor: "grey.50",
              borderRadius: "4px",
              flexShrink: 0,
            }}
          >
            <Button
              startIcon={<ArrowBackIosNew />}
              component={Link}
              to="/"
              sx={{ alignSelf: "start" }}
            >
              All Surveys
            </Button>

            <List>
              <ListItem disablePadding>
                <ListItemButton
                  component={NavLink}
                  to=""
                  end
                  sx={{
                    "&.active": {
                      color: theme.palette.primary.main,
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  <ListItemText primary="General" />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton
                  component={NavLink}
                  to="editor"
                  sx={{
                    "&.active": {
                      color: theme.palette.primary.main,
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  <ListItemText primary="Editor" />
                </ListItemButton>
              </ListItem>
            </List>
          </Stack>

          <Stack sx={{ flexGrow: 1, px: 1 }}>
            <Outlet />
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default EditSurvey;
