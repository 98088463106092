import { v4 as uuidv4 } from "uuid";
import {
  LOGIC_TYPE_DISPLAY,
  FLAGS_DICTIONARY,
  OPTION_TYPE_IMPORT,
} from "./constants";

const _optionsMapper = (question, option) => {
  // if type is import
  if (option.type === OPTION_TYPE_IMPORT) {
    const logicName = option.names[0]; // assumes there is only one item
    const transformedOption = {
      id: uuidv4(),
      type: "import",
      name: logicName,
      logic: null,
    }; // this is the import type option on the sharedb object

    const foundLogic = question.logicDefinitions.find(
      (ld) => ld.name === logicName
    ); // we try to find the logic in the logicDefinitions array in the importer object

    // if we find such an item then we just set the option's logic
    if (foundLogic) transformedOption.logic = foundLogic.logic;

    // and we return the transformedOption
    return transformedOption;
  }

  // if type is display or text, transforms only to text in the sharedb object
  const transformedOption = {
    id: uuidv4(),
    text: option.text,
    type: "text",
    alias: option.alias ? option.alias : "", // fallback alias to ""
    displayLogic: null,
  }; // this is the text type option on the sharedb object

  const flags = option.flags || []; // extract flags. fallback to empty array

  Object.keys(FLAGS_DICTIONARY).forEach((flagKey) => {
    // loop through all possible flag keys (e, u, w etc...)

    transformedOption[FLAGS_DICTIONARY[flagKey]] = flags.includes(flagKey);
    // transformedOption.FLAGS_DICTIONARY[flagKey] translates to transformedOption.unique, unique.fixed etc...
    // and the value will be true based on if flagKey exists on the importer's object flags property
  });

  // UP TO THIS POINT, the importer's text and display type will be handled the same way
  // since the structure will be the same in the sharedb object

  if (option.type === LOGIC_TYPE_DISPLAY) {
    // but for display type we try to fill the displayLogic property of the sharedb object
    // based on the item found in the logicDefinitions array in the importer object
    // like we do on import type

    const logicName = option.names[0]; // assumes there is only one item
    const foundLogic = question.logicDefinitions.find(
      (ld) => ld.name === logicName
    );

    // if we find such an item then we just set the transformedOption's displayLogic
    if (foundLogic) {
      transformedOption.displayLogic = {
        id: uuidv4(),
        type: "display",
        name: logicName,
        logic: foundLogic.logic,
      };
    }
  }

  return transformedOption;
};

const _logicDefinitionsMapper = (ld) => {
  return {
    id: uuidv4(),
    type: ld.type,
    name: ld.name,
    logic: ld.logic,
  };
};

const _logicDefinitionsFilter = (question) => {
  const filteredLogicDefinitions = question.logicDefinitions.filter((ld) =>
    question.questionLogic.some((ql) => ql.names.includes(ld.name))
  );
  // keep the logic definitions that concern the question (not options, groups).
  // that is achieved by looping through all logic definitions
  // and checking if there is an item in the questionLogic that includes the current logic definiton name in the names array.

  return filteredLogicDefinitions;
};

const transformImporterToShareDB = (survey) => {
  // console.log("======= IMPORTER =======");
  // console.log(survey.questions);
  // console.log(survey.questions[3]);

  const questions = survey.questions.map((question) => {
    const options = question.options.map((option) => {
      return _optionsMapper(question, option);
    });

    const groups = question.groups.map((group) => {
      return _optionsMapper(question, group);
    });

    const filteredLogicDefinitions = _logicDefinitionsFilter(question); // filter out logic definitons that concern the options/groups, keep only question level logic definitions

    const logicDefinitions = filteredLogicDefinitions.map((ld) => {
      return _logicDefinitionsMapper(ld);
    }); // map logic definitions objects to match the sharedb structure

    return {
      id: uuidv4(),
      content: question.content, // same as importer object
      description: question.description, // same as importer object
      groupsRandomized: question.groupsRandomized, // same as importer object
      maxGroupsAllowed: question.maxGroupsAllowed
        ? question.maxGroupsAllowed
        : "", // same as importer object, just fallback to "" if undefined
      maxOptionsAllowed: question.maxOptionsAllowed
        ? question.maxOptionsAllowed
        : "", // same as importer object, just fallback to "" if undefined
      name: question.name, // same as importer object
      optionsRandomized: question.optionsRandomized, // same as importer object
      title: question.title, // same as importer object
      type: question.type, // same as importer object
      options, // based on the result of _optionsMapper
      groups, // based on the result of _optionsMapper
      logicDefinitions, // based on the result of _logicDefinitionsMapper
    };
  });

  return { ...survey, questions };
};

export default transformImporterToShareDB;
